
import Header from './Headers/Header'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useMediaQuery, createTheme, ThemeProvider, Stack } from '@mui/material';
import { useNavigate, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardActions, Fab, CardContent, useTheme, Divider, Button, CardMedia } from "@mui/material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Footer from './footer'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import TextField from '@mui/material/TextField';


const theme = createTheme();

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));





function MainPage(props) {
    const [visiblePosts, setVisiblePosts] = useState(2); // Number of posts initially visible

    const loadMorePosts = () => {
        setVisiblePosts(prev => prev + 2); // Increase the number of visible posts
    };
    //const isAuthenticated = useAuth();
    const navigate = useNavigate();
    //const storedToken = localStorage.getItem('token');
    const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);
    const [RequestData, setRequestData] = useState([]);
    const [Image, setImageData] = useState([]);
    const [expanded, setExpanded] = React.useState('panel1');
    const [keyword, setKeyword] = useState('');
  const [error, setError] = useState(false); // State to manage error state

  const handleChange1 = (event) => {
    const value = event.target.value;
    setKeyword(value); // Update the keyword state with the new value

    // Add your validation logic here
    if (value.trim() === '') {
      setError(true); // Set error state if input is empty
    } else {
      setError(false); // Clear error state if input is not empty
    }
  };


    const handleadvance = (event) => {

        setImageData([]);
        const search = keyword

        fetch('https://server.facter.pk/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ search })

        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setRequestData(data);
                
                fetch('https://server.facter.pk/searchImage', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
        
                    body: JSON.stringify({search})
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        // Assuming data is an array of objects with 'filename' and 'base64image' properties
                        setImageData(data);
                        // Adjust timeZone if needed

                    })
                    .catch(error => {
                        console.error('Error making fetch request:', error);
                    });

            })
            .catch(error => {
                console.error('Error making fetch request:', error);
            });




    };

    const expandChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

   



    const handleLearnMoreClick = (id) => {

        //console.log('Clicked "Learn More" for card with ID:', id);
        localStorage.setItem('clickedRequestId', id);
        navigate('/update');
    };

  



    var currentDate = new Date();
    currentDate = currentDate.toDateString()
    return (

        <Box >

            <Header />

            <Stack direction={'column'} spacing={2} justifyContent={'center'} >


                <Box ml={0} flex={4} p={0} pt={5} sx={{ height: '100vh' }}>
                    <Accordion marginLeft={20} expanded={expanded === 'panel1'} onChange={expandChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Search Post for Update</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Box sx={{ display: 'flex' }} ml={2}>
                                <Typography mt={2} sx={{ mr: { sm: 5, xs: 2 }, fontWeight: 'bold' }}>Keyword  </Typography>
                                <TextField onChange={handleChange1}   id="outlined-basic" label="search" variant="outlined" />


                            </Box>




                            <Box sx={{ ml: { xs: 30, sm: 16 } }} mt={3}>
                                <Button variant="extended" onClick={handleadvance} sx={{ fontWeight: 'bold', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'black', boxShadow: 'none', boxShadow: 'none' } }}>
                                    SEARCH
                                </Button>
                            </Box>



                        </AccordionDetails>
                    </Accordion>



                    <div>
                        {RequestData.slice(0, visiblePosts).map((item, index) => (
                            <Card onClick={() => handleLearnMoreClick(item.id)} sx={{ display: { sm: 'flex', xs: 'block' }, width: { xs: 300, sm: '50%', lg: '70%' }, mt: { xs: 2, sm: 2 }, ml: { xs: 1, sm: 25 }, background: 'White', '&:hover': { cursor: 'pointer' } }}>
                                <CardMedia
                                    sx={{ objectFit: 'contain', width: 200, height: 160, cursor: 'pointer'   }}
                                    image={`data:image/png;base64,${Image[index]?.base64Data || ''}`}
                                    title={item.title}
                                />
                                <CardContent>
                                    <Typography sx={{ fontFamily: 'Open Sans, sans-serif', fontSize: { sm: 18, xs: 18 }, fontWeight: 'bold' }} gutterBottom variant="h5" component="div">
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>
                                        {item.added_datetime}
                                    </Typography>
                                </CardContent>

                            </Card>
                        ))}
                        {visiblePosts < RequestData.length && (
                            <Button onClick={loadMorePosts} Button variant="extended" width={1} sx={{ mb: 1, ml: { xs: 1, sm: 25 }, mt: 5, display: 'block', fontWeight: 'bold', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'black', boxShadow: 'none' } }} >
                                Load More
                            </Button>
                        )}
                    </div>


                </Box>

                <Footer />

            </Stack>

        </Box>


    );

}

export default MainPage;