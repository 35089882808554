
import Header from '../Headers/Header'
import Side from '../SideBar2'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useMediaQuery, createTheme, ThemeProvider, Stack } from '@mui/material';
import { useNavigate ,useHistory} from 'react-router-dom';
import React, {useEffect, useState}from 'react';
import { Box,Typography,Card,CardActions,Fab,CardContent,useTheme, Divider,Button,CardMedia } from "@mui/material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';


const theme = createTheme();



function MainPage(props) {
  //const isAuthenticated = useAuth();
  const navigate = useNavigate();
  //const storedToken = localStorage.getItem('token');
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);
  const [RequestData, setRequestData] = useState([]);


  const handleLearnMoreClick = (id) => {

    //console.log('Clicked "Learn More" for card with ID:', id);
    localStorage.setItem('clickedRequestId', id);
    navigate('/answerrequest');
    };

  useEffect(() => {
            window.scrollTo(0, 0);
            const userId= localStorage.getItem('savedId');

            fetch('https://server.facter.pk/requestspending', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
           

            })
            .then(response => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setRequestData(data);
                
            })
            .catch(error => {
                console.error('Error making fetch request:', error);
            });
                
  }, []);



 
    var currentDate = new Date();
    currentDate=currentDate.toDateString()
    return   (
       
        <Box >

            <Header/>    

            <Stack direction={'row'} spacing={2} justifyContent={'center'} >
               <Box   ml={0} flex={4} p={0} pt={5} sx={{height: '100vh'  }}>

                      <Box mb={1} mt={4} sx={{ display:{xs:'none' , sm:'flex' }}}>

                          <Typography variant="h6" color="#acabab" fontFamily={ '"Helvetica Neue"'} sx={{ml:9, fontWeight: 'bold',color:'#434343'}}>WELCOME TO FACTER</Typography>

                          <Typography variant="h6" color="#acabab" fontFamily={ '"Helvetica Neue"'} sx={{ml:36, fontWeight: 'bold',color:'#434343'}}>
                              {currentDate}
                              <icon  > <AccessTimeFilledIcon/> </icon>
                          </Typography>

                      </Box>

                      <Divider sx={{mt:3,mb:5,ml:{sm:11.5 ,xs:0}, width:{sm:'75%' , xs:'100%'},borderBottom: '2px solid #abacac'}}></Divider>


                          <Box sx={{ display:{xs:'block' , sm:'flex' } }}>

                                  <Typography variant="h6" color="#434343"  fontFamily={ '"Helvetica Neue"'} sx={{paddingLeft:{xs:'5px',sm:'5px'}, borderLeft: '4px solid red',ml:{sm:8,xs:2}, fontWeight: 'bold' }}>CLIENT REQUESTS</Typography>
                                  
                          </Box>

                          <Box sx={{ ml:13,mt:3, display:{xs:'block' , sm:'block'  }, gap: 2 }}>
                                {RequestData.map(item => (

                                      <Card sx={{ width:{xs:300,sm:500} , mt:{xs:2,sm:2} , background:'White',border: '1px solid #434343'}}>
                                         
                                          <CardContent>
                                              <Typography fontFamily={ '"Helvetica Neue"'} gutterBottom variant="h5" component="div">
                                              Description: {item.description}
                                              </Typography>
                                              <Typography variant="body2" color="text.secondary"  sx={{ maxWidth: '300px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                                              link: {item.link}
                                              </Typography>
                                              <Typography variant="body2" color="text.secondary"  sx={{ maxWidth: '300px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                                              Posted at: {item.timestamp}
                                              </Typography>

                                              <Typography variant="body2" color="text.secondary"  sx={{ maxWidth: '300px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                                              Given time: {item.time}  , {item.date}
                                              </Typography>

                                              <Typography variant="body2" color="text.secondary"  sx={{ maxWidth: '300px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                                              status: {item.status}
                                              </Typography>

                                              
                                          </CardContent>
                                          <CardActions>
                                          
                                              <Button size="small" onClick={() => handleLearnMoreClick(item.id)} sx={{ color:'white', background:' #434340' ,'&:hover': {
                                                  background:'black',borderColor: 'black' ,borderStyle: 'solid' , borderWidth:0,
                                              }}}>
                                                  Learn More
                                                  <InsertLinkIcon/>
                                              </Button>
                                          </CardActions>
                                      </Card>

                                  

                                  ))      
                                  
                                  
                                  }

                                  

                          </Box>

                        

                          </Box>


           
             
              <Side/>
              
            </Stack>

        </Box>
          
       
    );

}

export default MainPage;