import React from 'react';
import NavBar from './NavBar';
import NavBar2 from './NavBar2';
import Box from '@mui/material/Box';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import test from '../image/webBanner.webp'

class Post {
  constructor(description, image, imageText, linkText, title) {
    this.description = description;
    this.image = image;
    this.imageText = imageText;
    this.linkText = linkText;
    this.title = title;
  }
}

const NPost = new Post('WHAT WE DO', '', '', 'dfdf', 'TITLE');


export default function Header(props) {
  

  const { sections, title } = props;
  return (
    <div>
      <NavBar2/>
   
    <Box
    
    sx={{
      backgroundImage: `url(${test})`,
        backgroundSize: 'cover', // Ensures the background image covers the entire container
        backgroundRepeat: 'no-repeat', // Prevents the background image from repeating
        backgroundPosition: 'center', // Centers the background image
        height: '250px', // Set an initial height for the container
        '@media (min-width:600px)': {
          height: '500px', // Adjust the height for small screens and up
        },
        '@media (min-width:768px)': {
          height: '500px', // Adjust the height for medium screens and up
        },
        '@media (min-width:1080px)': {
          height: '500px', // Adjust the height for medium screens and up
        },
    
    }}>
     
    </Box>

    
    <NavBar/>
    
    </div>
       
  );
}