import React,  { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CryptoJS from 'crypto-js';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Mainlogo from './image/beta logo.png';
import powered from './image/MMD banner.jpeg';




// TODO remove, this demo shouldn't need to reset the theme.


export default function SignIn() {
  
  const defaultTheme = createTheme();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleTextFieldClick = () => {
    setIsClicked(true);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
 

 
  const handleSubmit = async (event) => {
    
    event.preventDefault();
    const data = new FormData(event.currentTarget);

   

    if(data.get('username')=='' || data.get('password')=='' ){// if the field is empty
         
          alert("Empty Fields")
          document.querySelector('#username').label='User Name'
          document.querySelector('#password').label='Password'
         
    }else{
      var User=data.get('username')
      var Pass=data.get('password')

      
        const plaintextData = {
            username: User,
            password: Pass
        };
  
        const key = 'mmfd12322122372mmfd-()-darth-vader-anakin';
  
        // Encrypt the data
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(plaintextData), key).toString();
      
      try {//send request to the server
        const response = await fetch('https://server.facter.pk/usersget', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ encryptedData: encryptedData}),
          
        });

        if (response.status === 200) {
          
          
          const responseData=await response.json();
          const key = 'mmfd12322122372mmfd-()-darth-vader-anakin';
          const bytes  = CryptoJS.AES.decrypt(responseData.encryptedData, key);
          const user = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        
         
          localStorage.setItem('TOKEN', responseData.token);
           localStorage.setItem('savedUsername', user.username);//change this please*** security risk
           localStorage.setItem('savedPrivilege', user.privilege);
           localStorage.setItem('savedId', user.id);
           localStorage.setItem('savednews', user.news);
          if(user.privilege ==='admin'||user.privilege ==='super admin' ||user.privilege ==='super duper admin'){

              
              navigate('/admin')
          }else{
            navigate('/home');

          }

                   

        

        } else if (response.status === 401) {
          alert("error")
          const errorData = await response.json();
          alert(`Login failed: ${errorData.message}`);
          navigate('/');
        }

      } catch (error) {
        alert(error)
        console.error('Error:', error);
        
      }

     


    }

    
    document.querySelector('#username').value=''
    document.querySelector('#password').value=''
    document.querySelector('#username').label='User Name'
    document.querySelector('#password').label='Password'
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: {xs:8 , sm:'20%',md:'20%',lg:'20%',xl:'50%'},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'black'
          }}
        >
          <img
                
                src={Mainlogo}
                alt="Eye icon" // Provide a meaningful alt text
               
              
          />
          <Typography component="h1" variant="h5" sx={{ color:'#434343',mt:1,ml:1,mb:1,fontWeight: 'bold',color:'#434343'}} fontFamily={ '"Helvetica Neue"'}>
            Log In
          </Typography>
          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="User Name"
              name="username"
              autoComplete="username"
              autoFocus
              onClick={handleTextFieldClick}
              sx={{
                '& input': {
                  borderColor: isClicked ? '#FBB042' : '#FBB042',
                },
                '& fieldset': {
                  borderColor: isClicked ? '#FBB042' : '#FBB042',
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              sx={{
                '& input': {
                  borderColor: '#FBB042', // Replace 'red' with the desired color
                },
                '& fieldset': {
                  borderColor: '#FBB042', // Replace 'red' with the desired color
                },
              }}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end" style={{color:'#FBB042' , bgcolor:'#FBB042'}}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
           

            
            <Button //login button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 5 , bgcolor: '#FBB042', 
              '&:hover': {
                bgcolor: '#434343', // Change to the desired hover color
              }, boxShadow: '2px 4px 1px 0px #dedede'}}
            >
              Log In
            </Button>


            <Box sx={{ marginLeft: 11 }}>
            <img
                
                src={powered}
                alt="Eye icon" // Provide a meaningful alt text
                width={200}
                sx={{ml:6}}
            />

            </Box>

        
            
          </Box>
        </Box>
       
      </Container>
    </ThemeProvider>
  );
}
