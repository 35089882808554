
import React, { useState, useEffect } from 'react';
import { styled, alpha, } from '@mui/material/styles';
import { Typography } from "@mui/material";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import mainlogo from '../image/0.5 banner.png';
import Avatar from  '@mui/material/Avatar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import geo from '../logos/geo.jpeg';
import hum from '../logos/Humnews.png';
import twentyfournews from '../logos/24 news.png'
import ajj from '../logos/ajj news.png'
import arab from '../logos/Arabnews.png'
import dawn from '../logos/Dawnnews.png'
import independent from '../logos/independenturdu.png'
import neo from '../logos/neo.png'
import samaa from '../logos/Samaa.png'
import soch from '../logos/Soch.png'
import talon from '../logos/Talonnews.png'
import tcm from '../logos/TCM.png'
import current from '../logos/Thecurrent.png'
import karachi from '../logos/timesofkarachi.png'
import tnn from '../logos/TNN.png'
import voa from '../logos/voa.png'

import test from '../logos/test.png'


// Define your theme with the Franklin font
const theme = createTheme({
  typography: {
    fontFamily: 'Franklin Gothic',
    fontWeightMedium:700
  },
});


  
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display:{xs:'none'},
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha('#f0f0f0', 1), color:'black',
  '&:hover': {
    backgroundColor: alpha('#f0f0f0', 1),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));



const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
 
}));



export default function PrimarySearchAppBar(props) {
 
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  };

  const formatTime = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    }).format(date);
  };

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');

  const handleInputChange = (event) => {
   
    setSearchText(event.target.value);
  };


  const handleSubmit = async (event) => {
            navigate('/')
  };


  const search1 = async (event) => {
    console.log('sdsd');
    localStorage.setItem('search',searchText)
    navigate('/home');
    navigate('/search');
    
   };

  const goHome = async (event) => {
    navigate('/home');
};

const reportPage = async (event) => {
  navigate('/report');
};


const archive = async (event) => {
  navigate('/home');
  navigate('/archivesearch');
 
};



const search2 = async (event) => {
  navigate('/home')
  navigate('/advancesearch');
 
 };

 const [logo, setlogo] = useState(null);
 React.useEffect(() => {

  if(localStorage.getItem('savednews') == 'geo'){
       setlogo(geo);
  }else if(localStorage.getItem('savednews') == 'hum'){
    setlogo(hum);
  }else if(localStorage.getItem('savednews') == 'twentyfournews'){
    setlogo(twentyfournews);
  }else if(localStorage.getItem('savednews') == 'ajj'){
    setlogo(ajj);
  }else if(localStorage.getItem('savednews') == 'arab'){
    setlogo(arab);
  }else if(localStorage.getItem('savednews') == 'dawn'){
    setlogo(dawn);
  }else if(localStorage.getItem('savednews') == 'independent'){
    setlogo(independent);
  }else if(localStorage.getItem('savednews') == 'neo'){
    setlogo(neo);
  }else if(localStorage.getItem('savednews') == 'samaa'){
    setlogo(samaa);
  }else if(localStorage.getItem('savednews') == 'soch'){
    setlogo(soch);
  }else if(localStorage.getItem('savednews') == 'talon'){
    setlogo(talon);
  }else if(localStorage.getItem('savednews') == 'tcm'){
    setlogo(tcm);
  }else if(localStorage.getItem('savednews') == 'current'){
    setlogo(current);
  }else if(localStorage.getItem('savednews') == 'karachi'){
    setlogo(karachi);
  }else if(localStorage.getItem('savednews') == 'tnn'){
    setlogo(tnn);
  }else if(localStorage.getItem('savednews') == 'voa'){
    setlogo(voa);
  }else if(localStorage.getItem('savednews') == 'test'){
    setlogo(test);
  }

 } );


    
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (

    <Box component="form" onSubmit={search1} noValidate>
    <Menu
    sx={{ color: 'red' }} 
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}

     
    >
      <MenuItem sx={{ color: 'black' }} >
        

        
      <Search>
      
           
           <StyledInputBase 
             placeholder="Search…"
             inputProps={{ 'aria-label': 'search' }}
             onChange={handleInputChange}
             
           />
         </Search>
          
      </MenuItem>
      <MenuItem sx={{ color: 'black' }}>
      
             <Button  type="submit"  
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 , bgcolor: '#fafafa', color:'#3c3c3c' ,boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  bgcolor: '#f5f5f5', // Change to the desired hover color
                },}}>
                
                SEARCH
              </Button>
              
       
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen} sx={{ color: 'red' }}>
      <Button  type="submit" onClick={search2}
              fullWidth
              width='20px'
              variant="contained"
              sx={{ mt: 0, mb: 2 , bgcolor: '#fafafa', color:'#3c3c3c', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                bgcolor: '#f5f5f5', // Change to the desired hover color
              },}}>
               ADVANCE 
            </Button>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen} sx={{ color: 'red' }}>
      <Button  type="submit" onClick={archive}
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 3 , bgcolor: '#fafafa', color:'#3c3c3c' , boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' ,
              '&:hover': {
                bgcolor: '#f5f5f5', // Change to the desired hover color
              },}}>
               ARCHIVE
            </Button>
      </MenuItem>
    </Menu>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
    <React.Fragment>
    <Box sx={{ flexGrow: 1,overflowX: 'hidden'  }}>
  <AppBar sx={{ position: 'absolute', backgroundColor: '#fafafa', width: '100%' }}>
    <Toolbar sx={{ borderBottom: '2px solid #fafafa', borderRadius: '5px', display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <Typography sx={{ fontSize: 14 }} color='#606060' variant="body1">
          {formatDate(dateTime)}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color='#606060' variant="body1">
          {formatTime(dateTime)}
        </Typography>
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
        <img
          src={mainlogo}
          alt="Eye icon"
          style={{ cursor: 'pointer' }}
          onClick={goHome}
          title='Facter'
        />
      </Box>
      
      <Button
        type="submit"
        onClick={reportPage}
        variant="contained"
        sx={{
          display: { xs: 'none', md: 'flex' },
          color: 'white',
          bgcolor: 'black',
          fontFamily: 'Franklin Gothic',
          pt: 2,
          pb: 2,
          lineHeight: 1.2,
          mt: 3,
          mb: 3,
          fontSize: 12,
          boxShadow: 'none',
          '&:hover': { bgcolor: 'black', color: 'white' }
        }}
      >
        WEEKLY REPORT
      </Button>
    </Toolbar>
  </AppBar>

  {renderMobileMenu}
  {renderMenu}
</Box>

    </React.Fragment>

    </ThemeProvider>
  );
}
