
import Header from '../Headers/Header'
import Side from '../SideBar2'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useMediaQuery, createTheme, ThemeProvider, Stack } from '@mui/material';
import { useNavigate, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardActions, Fab, CardContent, useTheme, Divider, Button, CardMedia } from "@mui/material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Footer from '../footer'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const theme = createTheme();



function MainPage(props) {
    //const isAuthenticated = useAuth();
    const navigate = useNavigate();
    //const storedToken = localStorage.getItem('token');
    const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);
    const [RequestData, setRequestData] = useState([]);
    const [RequestData2, setRequestData2] = useState([]);
    const [value, setValue] = React.useState('one');
    const [showBox, setShowBox] = useState(false);
    const [showBox2, setShowBox2] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setShowBox(true);
            setShowBox2(false);
        } else {
            setShowBox(false);
            setShowBox2(true);
        }
    };


    const handleLearnMoreClick = (id) => {

        //console.log('Clicked "Learn More" for card with ID:', id);
        localStorage.setItem('clickedRequestId', id);
        navigate('/datajob');
    };

    const handleLearnMoreClick2 = (id) => {

        //console.log('Clicked "Learn More" for card with ID:', id);
        localStorage.setItem('clickedRequestId', id);
        navigate('/answerjob');
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        const userId = localStorage.getItem('savedId');

        fetch('https://server.facter.pk/jobsuserget', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },


        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setRequestData(data);

            })
            .catch(error => {
                console.error('Error making fetch request:', error);
            });


            
        fetch('https://server.facter.pk/jobspending', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },


        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setRequestData2(data);

            })
            .catch(error => {
                console.error('Error making fetch request:', error);
            });



    }, []);



    var currentDate = new Date();
    currentDate = currentDate.toDateString()
    return (

        <Box >

            <Header />

            <Stack direction={'column'} spacing={2} justifyContent={'center'} >
                <Box ml={0} flex={4} p={0} pt={5} sx={{ height: '100vh' }}>


                    <Box sx={{ width: '100%', bgcolor: 'black' }}>
                        <Tabs  sx={{'& .MuiTabs-indicator': { backgroundColor: 'primary', height:4} }}  textColor='white' value={value} onChange={handleChange} centered>
                            <Tab sx={{ color: 'white',fontFamily:"Franklin Gothic", borderRight:'solid 1px' }} label="View Tagged Misinformation" />
                            <Tab sx={{ color: 'white',fontFamily:"Franklin Gothic" }} label="Answer Tagged Misinformation" />

                        </Tabs>
                    </Box>
                    {showBox && (
                       <Box sx={{ ml:{sm:'6%',xs:'3%',md:15},mt:3, display:{xs:'block' , sm:'block'  }, gap: 2,mb:20 }}>
                            {RequestData.map(item => (

                                <Card sx={{ width:{xs:300,sm:'50%',lg:'70%'},ml:{xs:1, sm:1}, mt:{xs:2,sm:2} , background:'White','&:hover':{cursor: 'pointer'} }}>

                                    <CardContent>
                                        <Typography fontFamily={'"Helvetica Neue"'} gutterBottom variant="h5" component="div">
                                            Description: {item.description}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>
                                            link: {item.link}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>
                                            Posted at: {item.timestamp}
                                        </Typography>



                                        <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>
                                            status: {item.status}
                                        </Typography>


                                    </CardContent>
                                    <CardActions>

                                        <Button size="small" onClick={() => handleLearnMoreClick(item.id)} disabled={item.status === 'pending'} sx={{
                                            color: 'white', background: ' #434340', '&:hover': {
                                                background: 'black', borderColor: 'black', borderStyle: 'solid', borderWidth: 0,
                                            }
                                        }}>
                                            Learn More
                                           
                                        </Button>
                                    </CardActions>
                                </Card>



                            ))







                            }




                        </Box>
                    )}


                    {showBox2 && (
                        <Box sx={{ ml:{sm:'6%',xs:'3%',md:15},mt:3, display:{xs:'block' , sm:'block'  }, gap: 2,mb:20 }}>
                            {RequestData2.map(item => (

                                <Card sx={{ width:{xs:300,sm:'50%',lg:'70%'},ml:{xs:1, sm:1}, mt:{xs:2,sm:2} , background:'White','&:hover':{cursor: 'pointer'} }}>

                                    <CardContent>
                                        <Typography fontFamily={'"Helvetica Neue"'} gutterBottom variant="h5" component="div">
                                            Description: {item.description}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>
                                            link: {item.link}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>
                                            Posted at: {item.timestamp}
                                        </Typography>



                                        <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>
                                            status: {item.status}
                                        </Typography>


                                    </CardContent>
                                    <CardActions>

                                        <Button size="small" onClick={() => handleLearnMoreClick2(item.id)}  sx={{
                                            color: 'white', background: ' #434340', '&:hover': {
                                                background: 'black', borderColor: 'black', borderStyle: 'solid', borderWidth: 0,
                                            }
                                        }}>
                                            Answer
                                            
                                        </Button>
                                    </CardActions>
                                </Card>



                            ))







                            }




                        </Box>
                    )}


                </Box>




                <Footer />

            </Stack>

        </Box>


    );

}

export default MainPage;