
import Header from './Headers/Header'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useMediaQuery, createTheme, ThemeProvider, Stack } from '@mui/material';
import { useNavigate ,useHistory} from 'react-router-dom';
import React, {useEffect, useState}from 'react';
import { Box,Typography,Card,CardActions,Fab,CardContent,useTheme, Divider,Button,CardMedia } from "@mui/material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Footer from './footer'


const theme = createTheme();



function MainPage(props) {

   

    const [visiblePosts, setVisiblePosts] = useState(2); // Number of posts initially visible

    const loadMorePosts = () => {
        setVisiblePosts(prev => prev + 2); // Increase the number of visible posts
    };
  //const isAuthenticated = useAuth();
  const navigate = useNavigate();
  //const storedToken = localStorage.getItem('token');
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);
  const [RequestData, setRequestData] = useState([]);
  const [Image, setImageData] = useState([]);
  const [hidePostCompleted, setHidePostCompleted] = useState(false);
 

  const handleLearnMoreClick = (id) => {

    //console.log('Clicked "Learn More" for card with ID:', id);
    localStorage.setItem('clickedRequestId', id);
    navigate('/update');
    };


    const hidepost = (e,id,index) => {

        e.stopPropagation();
        console.log(id)
      fetch('https://server.facter.pk/hidepost', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id })

    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })

      alert('THE POST IS NOW DELETED')
      setRequestData(prevData =>
        prevData.map(item => {
            if (item.id === id) {
                return { ...item, title: "DELETED POST",added_datetime: "" };
            }
            return item;
        })
    );
      
      
     
        };

       

  useEffect(() => {
            window.scrollTo(0, 0);

          

            fetch('https://server.facter.pk/updateview', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },

           
            

            })
            .then(response => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setRequestData(data);

                fetch('https://server.facter.pk/updateImage', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
        
                   
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        // Assuming data is an array of objects with 'filename' and 'base64image' properties
                        setImageData(data);

                    })
                    .catch(error => {
                        console.error('Error making fetch request:', error);
                    });


                
            })
            .catch(error => {
                console.error('Error making fetch request:', error);
            });

          
                
  }, [],[RequestData]);


    
    var currentDate = new Date();
    currentDate=currentDate.toDateString()
    return   (
       
        <Box >

            <Header/>    

              <Stack direction={'column'} spacing={2} justifyContent={'center'} >


                 <Box   ml={0} flex={4} p={0} pt={5} sx={{height: '100vh'  }}>

                            <div>
                                    {RequestData.slice(0, visiblePosts).map((item, index) => (
                                        <Card  onClick={() => handleLearnMoreClick(item.id)} sx={{display:{sm:'flex',xs:'block'}, width:{xs:300,sm:'50%',lg:'70%'} , mt:{xs:2,sm:2} ,ml:{xs:1, sm:25} , background:'White','&:hover':{cursor: 'pointer'} }}>
                                            <CardMedia
                                                sx={{ objectFit: 'contain', width: 200, height: 160, cursor: 'pointer'  }}
                                                image={`data:image/png;base64,${Image[index]?.base64Data || ''}`}
                                                title={item.title}
                                            />
                                            <CardContent>
                                                <Typography  sx={{ fontFamily: 'Open Sans, sans-serif', fontSize: { sm: 18, xs: 18 }, fontWeight: 'bold' }} gutterBottom variant="h5" component="div">
                                                {item.title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary"  sx={{ maxWidth: '300px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                                                {item.added_datetime}
                                                </Typography>
                                                <Button  onClick={(e) => hidepost(e, item.id,index)} sx={{mb:1,ml:{xs:1,sm:0}, mt:5,display: 'block', fontWeight: 'bold',backgroundColor: 'black', color: 'white' ,'&:hover': {backgroundColor: 'black', boxShadow: 'none'  } }}>DELETE</Button>      
                                            </CardContent>
                                        
                                        </Card>
                                    ))}
                                    {visiblePosts < RequestData.length && (
                                        <Button   onClick={loadMorePosts} Button variant="extended" width={1}  sx={{mb:1,ml:{xs:1,sm:25}, mt:5,display: 'block', fontWeight: 'bold',backgroundColor: 'black', color: 'white' ,'&:hover': {backgroundColor: 'black', boxShadow: 'none'  } }} >
                                            Load More
                                        </Button>
                                    )}
                            </div>

                
                 </Box>

              <Footer/>
              
            </Stack>

        </Box>
          
       
    );

}

export default MainPage;