
import Header from '../Headers/Header'
import Side from '../SideBar2'
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useMediaQuery, createTheme, ThemeProvider, Stack, Typography, Button, TextField, CardContent, Card, Radio, FormControlLabel, selectedOption, RadioGroup, FormControl, Fab } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Footer from '../footer';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import FormGroup from '@mui/material/FormGroup';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

import Checkbox from '@mui/material/Checkbox';


const theme = createTheme();
function SocialForm(props) {



  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });





  const navigate = useNavigate();
  const storedToken = localStorage.getItem('token');
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(false);

  //for handling the data insertion

  const [title, setText1] = useState('');
  const [link, setText2] = useState('');
  const [date, setText3] = useState('');
  const [time, setText4] = useState('');
  const [whats, setText5] = useState('');
  const [network, setText6] = useState('');
  const [account, setText7] = useState('');
  const [otherAccount, setText8] = useState('');
  const [tags, setText9] = useState('');
  const [benefit, setText10] = useState('');
  const [note, setText11] = useState('');
  const [relevence, setText12] = useState('');
  const [urgency, setText13] = useState('');
  const [critical, setText14] = useState('');
  const [source, setText15] = useState('');
  const [other1, setText16] = useState('');
  const [category, setText17] = useState('');
  const [other2, setText18] = useState('');
  const [category2, setText19] = useState('');
  const [other3, setText20] = useState('');
  const [type, setText21] = useState('');
  const [file, setFile] = useState('');
  const [filename, setFile2] = useState('');
  const [isPressed, setIsPressed] = useState(false);

  const handleTextChange1 = (event) => {
    setText1(event.target.value);
  };
  const handleTextChange2 = (event) => {
    setText2(event.target.value);
  };
  const handleTextChange3 = (date) => {
    date = date.format('YYYY-MM-DD');
    setText3(date);
  };
  const handleTextChange4 = (event) => {
    setText4(event.target.value);
  };
  const handleTextChange5 = (event) => {
    setText5(event.target.value);
  };
  const handleTextChange6 = (event) => {
    setText6(event.target.value);
  };
  const handleTextChange7 = (event) => {
    setText7(event.target.value);
  };
  const handleTextChange8 = (event) => {
    setText8(event.target.value);
  };
  const handleTextChange9 = (event) => {
    setText9(event.target.value);
  };
  const handleTextChange10 = (event) => {
    setText10(event.target.value);
  };
  const handleTextChange11 = (event) => {
    setText11(event.target.value);
  };
  const handleTextChange12 = (event) => {
    setText12(event.target.value);
  };
  const handleTextChange13 = (event) => {
    setText13(event.target.value);
  };
  const handleTextChange14 = (event) => {
    setText14(event.target.value);
  };
  const handleTextChange15 = (event) => {
    setText15(event.target.value);
  };
  const handleTextChange16 = (event) => {
    setText16(event.target.value);
  };



  const handleTextChange17 = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Append the selected option to the string with comma
      setText17(prevOptions => (prevOptions ? prevOptions + ', ' : '') + value);
    } else {
      // Remove the selected option from the string
      setText17(prevOptions => prevOptions.replace(new RegExp(value + ',? ?', 'g'), ''));
    }
  };
  const handleTextChange18 = (event) => {
    setText18(event.target.value);
  };
  const handleTextChange19 = (event) => {
    setText19(event.target.value);
  };
  const handleTextChange20 = (event) => {
    setText20(event.target.value);
  };
  const handleTextChange21 = (event) => {
    setText21(event.target.value);
  };

  const handleFileChange = (event) => {
    setIsPressed(true);

    setFile(event.target.files[0]);
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
      setFile2(fileName);

    }

  };
  //----------------------------------------------------------------------///

  React.useEffect(() => {


    //if (!storedToken) {
    // No token found, navigate to login
    //navigate('/login');
    //} else {
    //console.log('sdds');
    //const parsedToken =  JSON.parse(storedToken);
    //console.log(parsedToken.expiration,'dd');
    //console.log(Date.now());
    //if (parsedToken.expiration < Date.now()) {

    // Token has expired, clear it from local storage and navigate to login
    //localStorage.removeItem('token');
    //navigate('/login');


    //}
    //}

    setTokenCheckComplete(true);



  });

  //for form submission
  const FormSubmit = async (event) => {

    const jobId = localStorage.getItem('clickedRequestId');
    const job = localStorage.getItem('clickedRequestId');
    try {
      const response = await fetch('https://server.facter.pk/JobSubmit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ jobId, title, link, time, date, whats, network, account, otherAccount, tags, benefit, note, relevence, urgency, critical, source, other1, category, other2, category2, other3, type, filename }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Server response:', data);
    } catch (error) {
      console.error('Error sending data to server:', error.message);
    }


    try {
      const formData = new FormData();
      formData.append('file', file);


      const response = await fetch('https://server.facter.pk/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('File uploaded successfully:', responseData);
      } else {
        console.error('Error uploading file:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }


    try {
      const response = await fetch('https://server.facter.pk/SocialSubmit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title, link, time, date, whats, network, account, otherAccount, tags, benefit, note, relevence, urgency, critical, source, other1, category, other2, category2, other3, type, filename, job }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Server response:', data);
    } catch (error) {
      console.error('Error sending data to server:', error.message);
    }

    setText1('');
    setText2('');
    setText3('');
    setText4('');
    setText5('');
    setText6('');
    setText7('');
    setText8('');
    setText9('');
    setText10('');
    setText11('');
    setText12('');
    setText13('');
    setText14('');
    setText15('');
    setText16('');
    setText17('');
    setText18('');
    setText19('');
    setText20('');
    setText21('');
    window.scrollTo(0, 0);
    alert('Form submitted');
    navigate('/adminrequest');



  };







  return isTokenCheckComplete ? (

    <Box >

      <Header />

      <Stack direction={'column'} spacing={2} justifyContent={'center'} >


        <Box ml={0} flex={4} p={2} pt={10} sx={{ height: '100vh', pl: { xl: '24%', lg: '20%', sm: '8%', md: '8%', xs: '4%' } }}>
          <Typography variant="h6" color="black" fontFamily='Georgia' sx={{ ml: { sm: 10, sx: 7 }, fontSize: 24, fontWeight: 'bold' }}>Answer The Tagged Misinformation</Typography>
          <Box sx={{ display: { xs: 'block', sm: 'block' }, paddingTop: '10px', paddingBottom: '19px', width: { sm: 800, xs: 300 }, ml: { sm: 0, xs: 2 }, mt: 2, mb: 0 }}>



            <Card sx={{ ml: { sm: 10, xs: 1 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  TITLE
                </Typography>
                <Typography variant="body1" paragraph>
                  What is this fact check about.
                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange1}
                />

              </CardContent>
            </Card>



            <Card sx={{ ml: { sm: 10, xs: 1 }, mt: 1, width: { sm: 750, xs: 250 }, }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Link to the post
                </Typography>
                <Typography variant="body1" paragraph>
                  URL of the post
                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange2}
                />

              </CardContent>
            </Card>

            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 }, }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Posted Date
                </Typography>



                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker onChange={handleTextChange3} label="DATE PICKER" />
                  </DemoContainer>
                </LocalizationProvider>

              </CardContent>
            </Card>

            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 }, }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Posted Time
                </Typography>
                <Typography variant="h7" gutterBottom>
                  e.g 12:00 am
                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange4}
                />

              </CardContent>
            </Card>

            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  What is happening in the post?
                </Typography>
                <Typography variant="body1" paragraph>
                  (What does the post claim? Do contextualise the claims)
                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange5}
                />

              </CardContent>
            </Card>

            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Network Name
                </Typography>
                <Typography variant="body1" paragraph>
                  Please add the name of the page, group, community or hashtag on which the information is hosted <br></br> only add this if the account (see below) is different from the page / group on which is being used to share information
                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange6}
                />

              </CardContent>
            </Card>


            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Account Name
                </Typography>
                <Typography variant="body1" paragraph>
                  Please add the name of the account from which the information has been shared
                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange7}
                />

              </CardContent>
            </Card>





            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Other accounts that are sharing the same post
                </Typography>
                <Typography variant="body1" paragraph>

                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange8}
                />

              </CardContent>
            </Card>

            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Tags
                </Typography>
                <Typography variant="body1" paragraph>

                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange9}
                />

              </CardContent>
            </Card>


            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Who does this post potentially benefit?
                </Typography>
                <Typography variant="body1" paragraph>

                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange10}
                />

              </CardContent>
            </Card>


            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 }, }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Editor's note (Fact-checked information)
                </Typography>
                <Typography variant="body1" paragraph>
                  (Context and information about the post)
                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange11}
                />

              </CardContent>
            </Card>

            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Rate political relevance
                </Typography>
                <Typography variant="body1" paragraph>

                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup onChange={handleTextChange12} >
                    <FormControlLabel value="1" control={<Radio />} label="1" />
                    <FormControlLabel value="2" control={<Radio />} label="2" />
                    <FormControlLabel value="3" control={<Radio />} label="3" />
                    <FormControlLabel value="4" control={<Radio />} label="4" />
                    <FormControlLabel value="5" control={<Radio />} label="5" />
                  </RadioGroup>
                </FormControl>


              </CardContent>
            </Card>


            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Rate the urgency
                </Typography>
                <Typography variant="body1" paragraph>

                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup onChange={handleTextChange13}>
                    <FormControlLabel value="1" control={<Radio />} label="1" />
                    <FormControlLabel value="2" control={<Radio />} label="2" />
                    <FormControlLabel value="3" control={<Radio />} label="3" />
                    <FormControlLabel value="4" control={<Radio />} label="4" />
                    <FormControlLabel value="5" control={<Radio />} label="5" />
                  </RadioGroup>
                </FormControl>


              </CardContent>
            </Card>



            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Is it time critical
                </Typography>
                <Typography variant="body1" paragraph>

                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup onChange={handleTextChange14}>
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />

                  </RadioGroup>
                </FormControl>


              </CardContent>
            </Card>


            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Source
                </Typography>
                <Typography variant="body1" paragraph>

                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup onChange={handleTextChange15}>
                    <FormControlLabel value="Facebook" control={<Radio />} label="Facebook" />
                    <FormControlLabel value="X" control={<Radio />} label="X (formerly Twitter)" />
                    <FormControlLabel value="TikTok" control={<Radio />} label="TikTok" />
                    <FormControlLabel value="YouTube" control={<Radio />} label="YouTube" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />

                  </RadioGroup>
                </FormControl>


              </CardContent>
            </Card>

            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  If you have chosen others then please identify your answer
                </Typography>
                <Typography variant="body1" paragraph>
                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange16}
                />

              </CardContent>
            </Card>




            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Does it fall in any of the following categories?
                </Typography>
                <Typography variant="body1" paragraph>

                </Typography>

                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={category.includes('Incitement to violence')} onChange={handleTextChange17} value="Incitement to violence" />}
                      label="Incitement to violence"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={category.includes('Religiopolitical hate speech')} onChange={handleTextChange17} value="Religiopolitical hate speech" />}
                      label="Religiopolitical hate speech"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={category.includes('Gendered Disinformation')} onChange={handleTextChange17} value="Gendered Disinformation" />}
                      label="Gendered Disinformation"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={category.includes('Political propaganda')} onChange={handleTextChange17} value="Political propaganda" />}
                      label="Political propaganda"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={category.includes('Other')} onChange={handleTextChange17} value="Other" />}
                      label="Other"
                    />
                  </FormGroup>
                </FormControl>


              </CardContent>
            </Card>

            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 }, }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  If you have chosen others then please identify your answer
                </Typography>
                <Typography variant="body1" paragraph>
                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange18}
                />

              </CardContent>
            </Card>



            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 }, }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  In case of deceptive imagery/video, select the type that describes the content
                </Typography>
                <Typography variant="body1" paragraph>

                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup onChange={handleTextChange19}>
                    <FormControlLabel value="Shallow fakes((Photoshopped imagery, fake documents)" control={<Radio />} label="Shallow fakes(Photoshopped imagery, fake documents)" />
                    <FormControlLabel value="Deepfakes" control={<Radio />} label="Deepfakes" />
                    <FormControlLabel value="Edited or misleading videos/images/documents (such as false subtitles, falsified dates, false content, etc.)
" control={<Radio />} label="Edited or misleading videos/images/documents (such as false subtitles, falsified dates, false content, etc.)
" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />

                  </RadioGroup>
                </FormControl>


              </CardContent>
            </Card>

            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 }, }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  If you have chosen others then please identitfy your answer
                </Typography>
                <Typography variant="body1" paragraph>
                </Typography>
                <TextField
                  label="Your Answer"
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleTextChange20}
                />

              </CardContent>
            </Card>


            <Card sx={{ ml: { sm: 10, xs: 3 }, mt: 1, width: { sm: 750, xs: 250 } }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Type of content
                </Typography>
                <Typography variant="body1" paragraph>

                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup onChange={handleTextChange21}>
                    <FormControlLabel value="Suspected Information (Unverified Content)" control={<Radio />} label="Suspected Information (Unverified Content)" />
                    <FormControlLabel value="Misinformation (Documented & Fact Check by MMfD" control={<Radio />} label="Misinformation (Documented & Fact Checked by MMfD)" />
                    <FormControlLabel value="Verified/Published Fact-Checked Information" control={<Radio />} label="Verified/Published Fact-Checked Information" />
                    <FormControlLabel value="Misleading Content/Mal-information" control={<Radio />} label="Misleading Content/Mal-information" />
                    <FormControlLabel value="Hate speech/Incitement" control={<Radio />} label="Hate speech/Incitement" />

                  </RadioGroup>
                </FormControl>


              </CardContent>
            </Card>
            <Button component="label" variant="contained" startIcon={isPressed ? <CloudDoneIcon /> : <CloudUploadIcon />} sx={{ mt: 7, ml: 13, background: isPressed ? 'green' : 'black' }} onChange={handleFileChange}>
              Upload file
              <VisuallyHiddenInput type="file" />
            </Button>

          </Box>
          <Button variant="extended" onClick={FormSubmit} sx={{
            mb: 8, ml: 13, fontWeight: 'bold', backgroundColor: 'black', color: 'white', '&:hover': {
              backgroundColor: 'black'
            }
          }}>
            SUBMIT
          </Button>

        </Box>

        <Footer />



      </Stack>

    </Box>


  ) : null;

}

export default SocialForm;