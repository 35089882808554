import Header from './Headers/Header'
import Side from './SideBar2'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useMediaQuery, createTheme, ThemeProvider, Stack } from '@mui/material';
import { useNavigate ,useHistory} from 'react-router-dom';
import React, {useEffect, useState}from 'react';
import { Box,Typography,Card,CardActions,Fab,CardContent,useTheme, Divider,Button,CardMedia } from "@mui/material";
import Imran from './image/imranKhan.jpeg'
import CYBER from './image/cyber.jpeg'
import POLICE from './image/police.jpeg'
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';


function MainPage(props) {
    //const isAuthenticated = useAuth();
    const navigate = useNavigate();
    //const storedToken = localStorage.getItem('token');
    const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);

    const theme = createTheme({
        typography: {
          fontFamily: 'Georgia', // Specify Georgia as the font family
        },
      });
   
  
     //to handle the add request 
    const addRequest = () => {
      navigate('/addrequest')
    };


     //to handle the add request 
     const Latest = () => {
        navigate('/latest')
      };
  
     //to show the  request 
     const showRequest = () => {
      navigate('/showrequest')
    };


     //to show the  request 
     const showJob = () => {
        navigate('/showjob')
      };
  
  
     //to show the  request 
     const addJob = () => {
      navigate('/addjob')
    };

    return   ( 


        <AppBar  position="relative" overflowX= 'hidden'  height='100px' sx={{MaxWidth: '100%',backgroundColor:"#f0f0f0", mt:8,paddingRight:5}}>
        <Toolbar>
            <Box sx={{display:'flex', pb:15 ,pt:10,pl:{xs:'0%' ,sm:'9%',md:'9%',lg:'12%',xl:'16%'}}}>

                <Box sx={{display:{md:'block',sm:'none',xs:'none'} , width:{xl:900,lg:800,md:200,sm:200,xs:100} , color:"black"}}>

                <ThemeProvider theme={theme} width='100%'>
                   <Typography fontWeight='bold' fontSize={18}> ABOUT</Typography>
                </ThemeProvider>
                   <Typography> 

                   Facter seeks to address the specific challenges faced by Pakistani newsrooms,<br></br> particularly the lack of resources.<br></br> It has been strategically designed keeping collaboration and sustainability in mind,<br></br> and to cater specifically to the unique demands of newsrooms.

                   </Typography>
                </Box>


                <Box sx={{display:{md:'block',sm:'none',xs:'none'} , width:{lg:200,md:200,sm:200,xs:100}, color:"black",pl:{xs:'0%' ,sm:'9%',md:'9%',lg:'5%',xl:'10%'}}}>

                        <ThemeProvider theme={theme} width='100%'>
                        <Typography fontWeight='bold'  fontSize={18}>CONTACT US</Typography>
                        </ThemeProvider>


                        <ol style={{ listStyleType: 'none'}}>

                            <li style={{ cursor:'pointer' , display:'flex'}}>
                              <a href="https://twitter.com/mmfd_Pak"  target="_blank">
                                <TwitterIcon style={{ color: 'black' }}/>

                                </a>
                                <Typography mb={1}> Twitter</Typography>
                            </li>

                            <li style={{ cursor:'pointer', display:'flex' }}>
                            <a  href="https://www.instagram.com/mmfd_pak/"  target="_blank"> 
                                <InstagramIcon  style={{ color: 'black' }}/>
                                </a>

                                <Typography mb={1}> Instagram</Typography>
                            </li>

                            <li style={{ cursor:'pointer', display:'flex' }}>
                            <a href="https://www.facebook.com/mediamattersfordemocracy"  target="_blank">
                                <FacebookIcon  style={{ color: 'black' }}/>
                                </a>
                                <Typography mb={1}> Facebook</Typography>
                            </li>

                            <li style={{ cursor:'pointer' , display:'flex'}}>
                            <a href="https://www.linkedin.com/company/media-matters-for-democracy/"  target="_blank">
                                
                                <LinkedInIcon  style={{ color: 'black' }}/>
                                </a>
                                <Typography mb={1}> LinkedIn</Typography>
                            </li>
                      
                            <li  style={{ cursor:'pointer' , display:'flex'}}>
                            <a href="info@mediamatters.pk"  target="_blank">
                                <EmailIcon  style={{ color: 'black' }}/>
                                </a>
                                <Typography mb={1}> Email</Typography>
                            </li>
                      
                        </ol>
                        
                </Box>


                <Box width={1} sx={{display:{sm:'block',xs:'block'} , color:"black", pl:{xs:'0%' ,sm:'9%',md:'9%',lg:'10%',xl:'20%'}}}>
                <ThemeProvider theme={theme} width='100%'>
                        <Typography mb={1} fontWeight='bold'  fontSize={18}>KEY FEATURES</Typography>
                        </ThemeProvider>

                
                <Button  variant="extended" width={1} onClick={Latest} sx={{mb:1, display: 'block', fontWeight: 'bold', backgroundColor: 'black', color: 'white' ,'&:hover': {backgroundColor: 'black', boxShadow: 'none' }}}>
                           Latest Posts
                        </Button>
                        
                        <Button  variant="extended" width={1} onClick={addJob} sx={{mb:1, display: 'block', fontWeight: 'bold', backgroundColor: 'black', color: 'white' ,'&:hover': {backgroundColor: 'black', boxShadow: 'none' }}}>
                           Tag 
                           Misinformation
                        </Button>

                        <Button variant="extended" width={1} onClick={addRequest}  sx={{mb:1,display: 'block', fontWeight: 'bold',backgroundColor: 'black', color: 'white' ,'&:hover': {backgroundColor: 'black', boxShadow: 'none'  } }}>
                        Request a Fact-Check
                        </Button>

                    <Button variant="extended" width={1} onClick={showRequest}  sx={{mb:1,display: 'block', fontWeight: 'bold',backgroundColor: 'black', color: 'white' ,'&:hover': {backgroundColor: 'black', boxShadow: 'none' } }}>
                        SHOW REQUESTS
                    </Button>

                    <Button variant="extended" width={1} onClick={showJob}  sx={{display: 'block', fontWeight: 'bold',backgroundColor: 'black', color: 'white' ,'&:hover': {backgroundColor: 'black', boxShadow: 'none' } }}>
                        TAGGED MISINFORMATION
                    </Button>

               
                </Box>
              
            </Box>



        </Toolbar>
        </AppBar>
     



     );

    }
    
    export default MainPage;
  