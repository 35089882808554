import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Modal as BaseModal } from "@mui/base/Modal";
import { Modal } from "@mui/base/Modal";
import { PieChart } from "@mui/x-charts/PieChart";
import {
  Box,
  Grid,
  Stack,
  Divider,
  Typography,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header2 from "./Headers/NavBar2";
import logo from "./logos/test.png";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const data = [
  { name: "JAN", value: 2 },
  { name: "FEB", value: 3 },
  { name: "MAR", value: 5.5 },
  { name: "APR", value: 8.5 },
  { name: "MAY", value: 1.5 },
  { name: "JUN", value: 5 },
  { name: "JUL", value: 10 },
  { name: "AUG", value: 4 },
  { name: "SEP", value: 3 },
  { name: "OCT", value: 8 },
  { name: "NOV", value: 2 },
  { name: "DEC", value: 3 },
];

const CustomGradient = () => (
  <defs>
    <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#FFA07A" stopOpacity={0.8} />
      <stop offset="95%" stopColor="#FF6347" stopOpacity={0.2} />
    </linearGradient>
  </defs>
);
function MainPage(props) {
  const navigate = useNavigate();

  const [isTokenCheckComplete, setTokenCheckComplete] = useState(false);
  const [postCounts, setPostCounts] = useState({
    postsToday: 0,
    postsThisWeek: 0,
    totalPosts: 0,
  });
  const [platforms, setPlatforms] = useState([]);
  const [categories, setCategory] = useState([]);
  const [platforms2, setPlatforms2] = useState([]);
  const [categories2, setCategory2] = useState([]);
  const [data, setdata] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [graphIndex, setGraphIndex] = useState(0);

const toggleVisibility = () => {
  setGraphIndex((prevIndex) => (prevIndex + 1) % 3); // Cycle through 0, 1, 2
};

  useEffect(() => {
    fetch("https://server.facter.pk/postCounts")
      .then((response) => response.json())
      .then((data) => {
        setPostCounts(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching post counts:", err);
        setError("Error fetching post counts");
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch("https://server.facter.pk/monthlyPostCounts")
      .then((response) => response.json())
      .then((data) => {
        setdata(data);
      })
      .catch((err) => {
        console.error("Error fetching post counts:", err);
        setError("Error fetching post counts");
        setLoading(false);
      });
  }, []);

  const style = {
    position: "absolute",
    top: "60%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",

    boxShadow: 10,
    p: 4,
  };

  const userRole = localStorage.getItem("savedPrivilege");
  const isAdmin = () => {
    return userRole == "admin";
  };

  const isDuperAdmin = () => {
    return userRole == "super duper admin";
  };

  //to see the request of the clients
  const showrequest = () => {
    navigate("/adminrequest");
  };

  const showjobs = () => {
    navigate("/adminjob");
  };

  const SocialForm = () => {
    navigate("/social");
  };

  const EditorialForm = () => {
    navigate("/editorial");
  };

  const Generate = () => {
    navigate("/generatereport");
  };

  const UpdateView = () => {
    navigate("/viewCheck");
  };

  const UpdateView2 = () => {
    navigate("/viewCheck2");
  };

  const ViewStats = () => {
    navigate("/statsBoard");
  };

  const ProfileClick = () => {
    var per = localStorage.getItem("savedPrivilege");
    if (per !== "client") {
      navigate("/admin");
    }
  };

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (localStorage.getItem("savedPrivilege") === "client") {
      navigate("/");
    }

    fetch("https://server.facter.pk/validateToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("TOKEN"),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.valid === false) {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });

    fetch("https://server.facter.pk/platformsCount") // Assuming the API is served from the same domain
      .then((response) => response.json())
      .then((data) => {
        setPlatforms(data); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    fetch("https://server.facter.pk/categoryCount") // Assuming the API is served from the same domain
      .then((response) => response.json())
      .then((data) => {
        setCategory(data); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });


      fetch("https://server.facter.pk/platformsCountForMonth") // Assuming the API is served from the same domain
      .then((response) => response.json())
      .then((data) => {
        setPlatforms2(data); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    fetch("https://server.facter.pk/categoryCountForMonth") // Assuming the API is served from the same domain
      .then((response) => response.json())
      .then((data) => {
        setCategory2(data); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    setTokenCheckComplete(true);
  }, [navigate]);

  return isTokenCheckComplete ? (
    <Box sx={{}}>
      <Header2 />

      <Grid container sx={{ display: "flex", direction: "column" }}>
        <Grid item xs={12} md={2} lg={1.7}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
              backgroundColor: "white",
              boxShadow: "3px 0px 10px rgba(0, 0, 0, 0.3)",
              height: "100vh",
              borderRight: "1px solid black",
            }}
          >
            {/* Sidebar content */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: { md: "47%", xl: "39%" },

                marginLeft: "10%",
              }}
            >
              <Avatar
                alt={localStorage.getItem("savedUsername")}
                onClick={ProfileClick}
                sx={{
                  bgcolor: "gray",
                  cursor: "pointer",
                  marginTop: "2%",
                }}
                variant="square"
              />
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderRightWidth: 2,
                  marginLeft: 3,
                  height: "120%",
                  bgcolor: "black",
                }}
              />
              <Typography
                sx={{
                  marginLeft: "10%",
                  marginTop: "5%",

                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {localStorage.getItem("savedUsername")}
              </Typography>
            </Box>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                borderRightWidth: 3,
                marginTop: 1.2,
                bgcolor: "black",
              }}
            />

            <Box sx={{ marginTop: "20%" }}>
              <Box
                sx={{
                  marginTop: "4%",
                  gap: 0.2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start", // Centers content horizontally
                  alignItems: "start", // Centers content vertically
                  marginLeft: { xs: 0, md: 0, lg: 1 },
                  width: "100%", // Makes the box full width to center horizontally
                }}
              >
                <Button
                  variant="extended"
                  onClick={EditorialForm}
                  sx={{
                    display: "block",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    color: "black",

                    fontSize: { xs: 0, md: 12.5, lg: 14 },
                    "&:hover": { backgroundColor: "white", boxShadow: "none" },
                  }}
                >
                  Write Blog
                </Button>

                <Button
                  onClick={SocialForm}
                  variant="extended"
                  sx={{
                    display: "block",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    color: "black",
                    fontSize: { xs: 0, md: 12.5, lg: 14 },
                    "&:hover": { backgroundColor: "white", boxShadow: "none" },
                  }}
                >
                  New Post
                </Button>

                <Button
                  disabled={isAdmin()}
                  variant="extended"
                  onClick={UpdateView}
                  sx={{
                    display: "block",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    color: "background",
                    fontSize: { xs: 0, md: 12.5, lg: 14 },
                    "&:hover": { backgroundColor: "white", boxShadow: "none" },
                  }}
                >
                  View Drafts
                </Button>

                <Button
                  disabled={isAdmin()}
                  variant="extended"
                  onClick={UpdateView2}
                  sx={{
                    display: "block",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    color: "black",
                    fontSize: { xs: 0, md: 12.5, lg: 14 },
                    "&:hover": { backgroundColor: "white", boxShadow: "none" },
                  }}
                >
                  Update posts
                </Button>

                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{
                    width: "90%",
                    borderRightWidth: 2,
                    marginTop: 3,
                    bgcolor: "black",
                  }}
                />

                <Button
                  variant="extended"
                  onClick={showjobs}
                  sx={{
                    marginTop: "4%",
                    display: "block",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    color: "black",
                    fontSize: { xs: 0, md: 12.5, lg: 14 },
                    "&:hover": { backgroundColor: "white", boxShadow: "none" },
                  }}
                >
                  Pending Jobs
                </Button>

                <Button
                  variant="extended"
                  onClick={Generate}
                  sx={{
                    display: "block",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    color: "black",
                    fontSize: { xs: 0, md: 12.5, lg: 14 },

                    "&:hover": { backgroundColor: "white", boxShadow: "none" },
                  }}
                >
                  Write Report
                </Button>

                <Button
                  disabled={isAdmin()}
                  variant="extended"
                  onClick={UpdateView}
                  sx={{
                    display: "block",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    color: "black",
                    fontSize: { xs: 0, md: 12.5, lg: 14 },
                    "&:hover": { backgroundColor: "white", boxShadow: "none" },
                  }}
                >
                  View Drafts
                </Button>

                <Button
                  disabled={!isDuperAdmin()}
                  variant="extended"
                  onClick={ViewStats}
                  sx={{
                    display: "block",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    color: "black",
                    fontSize: { xs: 0, md: 12.5, lg: 14 },
                    "&:hover": { backgroundColor: "white", boxShadow: "none" },
                  }}
                >
                  View Stats
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={8} overflowY="auto">
          <Box
            sx={{
              position: "fixed",
              bgcolor: "#414042",
              width: "100%",
              height: "100px",
              marginTop: { xs: "6%", md: "6%", xl: "4.5%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                direction: "column",
                padding: { xs: "0rem", md: "1rem" },
                paddingRight: { xs: "0%", md: "10%", lg: "20%" },
                justifyContent: "space-evenly",
                marginBottom: { xs: "10%", md: "2%" },
                marginTop: { xs: "8%", md: "0%" },
              }}
            >
              <Card
                sx={{
                  height: { xs: 100, md: 150 },
                  width: { xs: 150, md: 200 },
                  marginTop: { xs: 3 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: 3,

                  cursor: "pointer",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition
                  "&:hover": {
                    boxShadow: 6, // Increase shadow on hover
                    transform: "scale(1.05)", // Slightly enlarge the card on hover
                  },
                }}
              >
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", md: "20px" },
                      fontWeight: "bold",
                    }}
                  >
                    TOTAL POST
                  </Typography>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ marginLeft: { xs: 1.5, md: 3 } }}
                  >
                    {postCounts.totalPosts}
                  </Typography>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: { xs: 100, md: 150 },
                  width: { xs: 150, md: 200 },
                  marginTop: { xs: 3 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: 3,

                  cursor: "pointer",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition
                  "&:hover": {
                    boxShadow: 6, // Increase shadow on hover
                    transform: "scale(1.05)", // Slightly enlarge the card on hover
                  },
                }}
              >
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", md: "20px" },
                      fontWeight: "bold",
                    }}
                  >
                    THIS WEEK
                  </Typography>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ marginLeft: { xs: 1.5, md: 3 } }}
                  >
                    {postCounts.postsThisWeek <= 9
                      ? "0" + postCounts.postsThisWeek
                      : postCounts.postsThisWeek}
                  </Typography>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: { xs: 100, md: 150 },
                  width: { xs: 160, md: 200 },
                  marginTop: { xs: 3 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: 3,

                  cursor: "pointer",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition
                  "&:hover": {
                    boxShadow: 6, // Increase shadow on hover
                    transform: "scale(1.05)", // Slightly enlarge the card on hover
                  },
                }}
              >
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", md: "20px" },
                      fontWeight: "bold",
                    }}
                  >
                    POSTED TODAY
                  </Typography>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ marginLeft: { xs: 3, md: 5 } }}
                  >
                    {postCounts.postsToday <= 9
                      ? "0" + postCounts.postsToday
                      : postCounts.postsToday}
                  </Typography>
                </CardContent>
              </Card>
            </Box>

            <Box
              sx={{
                marginLeft: { xs: "10%", md: "0%" },
                marginRight: { xs: "0%", md: "5%" },
              }}
            >
              <div>
  <Button
    onClick={toggleVisibility}
    sx={{
      marginLeft: "3%",
      marginBottom: "1%",
      bgcolor: "black",
      color: "white",
      "&:hover": {
        bgcolor: "black",
        color: "white",
      },
    }}
  >
    {graphIndex === 0
      ? "Yearly Graphs of Categories"
      : graphIndex === 1
      ? "Monthly Post Count"
      : "Additional Graphs"}
  </Button>

  {graphIndex === 0 && (
    <ResponsiveContainer width="87.5%" height={300} paddingTop="10%" marginTop="10%">
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, 110]} />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="value"
          stroke="orange"
          dot={{ stroke: "orange", strokeWidth: 2 }}
        >
          <LabelList
            dataKey="value"
            position="top"
            content={({ x, y, value }) => (
              <text
                x={x}
                y={y}
                dy={-10}
                fontSize={15}
                textAnchor="middle"
                fill="orange"
              >
                {value}
              </text>
            )}
          />
        </Line>
      </LineChart>
    </ResponsiveContainer>
  )}

  {graphIndex === 1 && (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        marginLeft: "3%",
        marginTop: "0.5%",
      }}
    >
      {/* Your Monthly Post Count graphs */}
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography>Platforms</Typography>
          <BarChart
            xAxis={[
              {
                scaleType: "band",
                data: ["Facebook", "TikTok", "Twitter", "YouTube", "Others"],
              },
            ]}
            series={[
              {
                data: [
                  platforms[0]?.count || 0,
                  platforms[2]?.count || 0,
                  (platforms[3]?.count || 0) +
                    (platforms[4]?.count || 0) +
                    (platforms[5]?.count || 0) +
                    (platforms[6]?.count || 0),
                  platforms[7]?.count || 0,
                  platforms[1]?.count || 0,
                ],
                color: "orange",
              },
            ]}
            width={500}
            height={300}
          />
        </CardContent>
      </Card>

      <Card sx={{ minWidth: 500 }}>
        <CardContent>
          <Typography>Category</Typography>
          <PieChart
            series={[
              {
                data: [
                  {
                    id: 0,
                    value:
                      (categories[1]?.count || 0) +
                      (categories[2]?.count || 0) +
                      (categories[3]?.count || 0),
                    label: "MisInformation",
                    color: "orange",
                  },
                  {
                    id: 1,
                    value:
                      (categories[6]?.count || 0) + (categories[7]?.count || 0),
                    label: "Suspected Information",
                    color: "#fff8e1",
                  },
                  {
                    id: 2,
                    value:
                      (categories[8]?.count || 0) +
                      (categories[9]?.count || 0) +
                      (categories[10]?.count || 0),
                    label: "Verified Information",
                    color: "#ffd34f",
                  },
                  {
                    id: 3,
                    value:
                      (categories[4]?.count || 0) + (categories[5]?.count || 0),
                    label: "Misleading Content",
                    color: "#ffc829",
                  },
                  {
                    id: 4,
                    value: categories[0]?.count || 0,
                    label: "Hate Speech",
                    color: "#ffebb3",
                  },
                ],
              },
            ]}
            width={600}
            height={200}
          />
        </CardContent>
      </Card>
    </Box>
  )}

  {graphIndex === 2 && (
    <Box
    sx={{
      display: "flex",
      gap: 2,
      marginLeft: "3%",
      marginTop: "0.5%",
    }}
  >
    {/* Your Monthly Post Count graphs */}
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography>Platforms</Typography>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: ["Twitter","Facebook", "TikTok", , "YouTube", "Others"],
            },
          ]}
          series={[
            {
              data: [
                platforms2[0]?.count || 0,
                platforms2[2]?.count || 0,
                (platforms2[3]?.count || 0) +
                  (platforms2[4]?.count || 0) +
                  (platforms2[5]?.count || 0) +
                  (platforms2[6]?.count || 0),
                platforms2[7]?.count || 0,
                platforms2[1]?.count || 0,
              ],
              color: "orange",
            },
          ]}
          width={500}
          height={300}
        />
      </CardContent>
    </Card>

    <Card sx={{ minWidth: 500 }}>
      <CardContent>
        <Typography>Category</Typography>
        <PieChart
          series={[
            {
              data: [
                {
                  id: 0,
                  value:
                    (categories2[1]?.count || 0) +
                    (categories2[2]?.count || 0) +
                    (categories2[0]?.count || 0),
                  label: "MisInformation",
                  color: "orange",
                },
                {
                  id: 1,
                  value:
                    (categories2[6]?.count || 0) + (categories2[7]?.count || 0),
                  label: "Suspected Information",
                  color: "#fff8e1",
                },
                {
                  id: 2,
                  value:
                    (categories2[8]?.count || 0) +
                    (categories2[9]?.count || 0) +
                    (categories2[10]?.count || 0),
                  label: "Verified Information",
                  color: "#ffd34f",
                },
                {
                  id: 3,
                  value:
                    (categories2[4]?.count || 0) + (categories2[5]?.count || 0),
                  label: "Misleading Content",
                  color: "#ffc829",
                },
                {
                  id: 4,
                  value: categories2[1]?.count || 0,
                  label: "Hate Speech",
                  color: "#ffebb3",
                },
              ],
            },
          ]}
          width={600}
          height={200}
        />
      </CardContent>
    </Card>
  </Box>
  )}
</div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : null;
}

export default MainPage;
