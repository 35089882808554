import * as React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import { useNavigate, Link } from 'react-router-dom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import mainlogo from '../image/0.5 logo.png';
import Avatar from '@mui/material/Avatar';
import { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import geo from '../logos/geo.jpeg';
import hum from '../logos/Humnews.png';
import twentyfournews from '../logos/24 news.png'
import ajj from '../logos/ajj news.png'
import arab from '../logos/Arabnews.png'
import dawn from '../logos/Dawnnews.png'
import independent from '../logos/independenturdu.png'
import neo from '../logos/neo.png'
import samaa from '../logos/Samaa.png'
import soch from '../logos/Soch.png'
import talon from '../logos/Talonnews.png'
import tcm from '../logos/TCM.png'
import current from '../logos/Thecurrent.png'
import karachi from '../logos/timesofkarachi.png'
import tnn from '../logos/TNN.png'
import voa from '../logos/voa.png'

import test from '../logos/test.png'


// Define your theme with the Franklin font
const theme = createTheme({
  typography: {
    fontFamily: 'Franklin Gothic',
    fontWeightMedium: 700
  },
});



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display: { xs: 'none' },
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha('#f0f0f0', 1), color: 'black',
  '&:hover': {
    backgroundColor: alpha('#f0f0f0', 1),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));



const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },

}));



export default function PrimarySearchAppBar(props) {


  const navigate = useNavigate();

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
        search1(); // Call your search1 function here
    }
};

  const [searchText, setSearchText] = useState('');

  const ProfileClick = () =>{

    var per=localStorage.getItem("savedPrivilege")
    if(per != "client"){
      navigate("/admin")
    }
   
  }
  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };


  const handleSubmit = async (event) => {
    navigate('/')
  };


  const search1 = async (event) => {
    localStorage.setItem('search', searchText)
  
    navigate('/search');

  };

  const goHome = async (event) => {
    navigate('/home')
  };


  const archive = async (event) => {
    navigate('/archivesearch');

  };



  const search2 = async (event) => {
    navigate('/advancesearch');

  };

  const [logo, setlogo] = useState(null);
  React.useEffect(() => {

    if (localStorage.getItem('savednews') == 'geo') {
      setlogo(geo);
    } else if (localStorage.getItem('savednews') == 'hum') {
      setlogo(hum);
    } else if (localStorage.getItem('savednews') == 'twentyfournews') {
      setlogo(twentyfournews);
    } else if (localStorage.getItem('savednews') == 'ajj') {
      setlogo(ajj);
    } else if (localStorage.getItem('savednews') == 'arab') {
      setlogo(arab);
    } else if (localStorage.getItem('savednews') == 'dawn') {
      setlogo(dawn);
    } else if (localStorage.getItem('savednews') == 'independent') {
      setlogo(independent);
    } else if (localStorage.getItem('savednews') == 'neo') {
      setlogo(neo);
    } else if (localStorage.getItem('savednews') == 'samaa') {
      setlogo(samaa);
    } else if (localStorage.getItem('savednews') == 'soch') {
      setlogo(soch);
    } else if (localStorage.getItem('savednews') == 'talon') {
      setlogo(talon);
    } else if (localStorage.getItem('savednews') == 'tcm') {
      setlogo(tcm);
    } else if (localStorage.getItem('savednews') == 'current') {
      setlogo(current);
    } else if (localStorage.getItem('savednews') == 'karachi') {
      setlogo(karachi);
    } else if (localStorage.getItem('savednews') == 'tnn') {
      setlogo(tnn);
    } else if (localStorage.getItem('savednews') == 'voa') {
      setlogo(voa);
    } else if (localStorage.getItem('savednews') == 'test') {
      setlogo(test);
    }

  });



  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      sx={{ color: 'red' }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}


    >
      <MenuItem sx={{ color: 'black' }} >
        <Search>

          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleInputChange}
          />
        </Search>

      </MenuItem>
      <MenuItem sx={{ color: 'black' }}>
        <Button type="submit" onClick={search1}
          fullWidth
          variant="contained"
          sx={{
            mt: 3, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none',
            '&:hover': {
              bgcolor: '#f5f5f5', // Change to the desired hover color
            },
          }}>
          SEARCH
        </Button>

      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen} sx={{ color: 'red' }}>
        <Button type="submit" onClick={search2}
          fullWidth
          width='20px'
          variant="contained"
          sx={{
            mt: 0, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none',
            '&:hover': {
              bgcolor: '#f5f5f5', // Change to the desired hover color
            },
          }}>
          ADVANCE
        </Button>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen} sx={{ color: 'red' }}>
        <Button type="submit" onClick={archive}
          fullWidth
          variant="contained"
          sx={{
            mt: 0, mb: 3, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none',
            '&:hover': {
              bgcolor: '#f5f5f5', // Change to the desired hover color
            },
          }}>
          ARCHIVE
        </Button>
      </MenuItem>



      <MenuItem onClick={handleProfileMenuOpen} sx={{ color: 'red' }}>
        <Button type="submit" component={Link} to="/view" onClick={() => localStorage.setItem('search', 'Suspected')}
          fullWidth
          variant="contained"
          sx={{
            mt: 0, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none',
            '&:hover': {
              bgcolor: 'white', boxShadow: 'none', // Change to the desired hover color
            },
          }}>
          Suspected Information
        </Button>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen} sx={{ color: 'red' }}>
        <Button type="submit" component={Link} to="/view" onClick={() => localStorage.setItem('search', 'Misinformation (Documented & Fact Check by MMfD')}
          fullWidth
          variant="contained"
          sx={{
            mt: 0, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none',
            '&:hover': {
              bgcolor: 'white', boxShadow: 'none', // Change to the desired hover color
            },
          }}>
          Misinformation
        </Button>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen} sx={{ color: 'red' }}>
        <Button type="submit" component={Link} to="/view" onClick={() => localStorage.setItem('search', 'Verified/Published Fact-Checked Information')}
          fullWidth
          variant="contained"
          sx={{
            mt: 0, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none',
            '&:hover': {
              bgcolor: 'white', boxShadow: 'none', // Change to the desired hover color
            },
          }}>
          Verified Information
        </Button>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen} sx={{ color: 'red' }}>
        <Button type="submit" component={Link} to="/view" onClick={() => localStorage.setItem('search', 'Misleading Content/Mal-information')}
          fullWidth
          variant="contained"
          sx={{
            mt: 0, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none',
            '&:hover': {
              bgcolor: 'white', boxShadow: 'none', // Change to the desired hover color
            },
          }}>
          Misleading Content
        </Button>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen} sx={{ color: 'red' }}>
        <Button type="submit" component={Link} to="/view" onClick={() => localStorage.setItem('search', 'Hate speech/Incitement')}
          fullWidth
          variant="contained"
          sx={{
            mt: 0, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none',
            '&:hover': {
              bgcolor: 'white', boxShadow: 'none', // Change to the desired hover color
            },
          }}>
          Hate Speech
        </Button>

      </MenuItem>
    </Menu>

  );

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Box sx={{ flexGrow: 1 }}>

          <AppBar sx={{ position: 'fixed', backgroundColor: '#fafafa', bottom: 0, top: 'auto' }}>
            <Toolbar sx={{ borderBottom: '2px solid #fafafa', borderRadius: '5px' }}>



              <Box sx={{ flexGrow: 1 }} />


              <Button type="submit" component={Link} to="/view" onClick={() => localStorage.setItem('search', 'Misinformation (Documented & Fact Check by MMfD')}
                fullWidth
                variant="contained"
                sx={{
                  mt: 3, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none', display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                  '&:hover': {
                    bgcolor: 'white', boxShadow: 'none', // Change to the desired hover color
                  },
                }}>
                Misinformation
              </Button>
              <Button type="submit" component={Link} to="/view" onClick={() => localStorage.setItem('search', 'Suspected')}
                fullWidth
                variant="contained"
                sx={{
                  mt: 3, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none', display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                  '&:hover': {
                    bgcolor: 'white', boxShadow: 'none', // Change to the desired hover color
                  },
                }}>
                Suspected Information
              </Button>


              

              <Button type="submit" component={Link} to="/view" onClick={() => localStorage.setItem('search', 'Verified/Published Fact-Checked Information')}
                fullWidth
                variant="contained"
                sx={{
                  mt: 3, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none', display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                  '&:hover': {
                    bgcolor: 'white', boxShadow: 'none', // Change to the desired hover color
                  },
                }}>
                Verified Information
              </Button>



              <Button type="submit" component={Link} to="/view" onClick={() => localStorage.setItem('search', 'Misleading Content/Mal-information')}
                fullWidth
                variant="contained"
                sx={{
                  mt: 3, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none', display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                  '&:hover': {
                    bgcolor: 'white', boxShadow: 'none', // Change to the desired hover color
                  },
                }}>
                Misleading Content
              </Button>

              <Button type="submit" component={Link} to="/view" onClick={() => localStorage.setItem('search', 'Hate speech/Incitement')}
                fullWidth
                variant="contained"
                sx={{
                  mt: 3, mb: 2, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none', display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
                  '&:hover': {
                    bgcolor: 'white', boxShadow: 'none', // Change to the desired hover color
                  },
                }}>
                Hate Speech
              </Button>

              <Search sx={{ display: { xs: 'none', md: 'flex', sm: 'none' } }}>

                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterPress}
                  startAdornment={
                    <InputAdornment position="end" >
                      <SearchIcon />
                    </InputAdornment>
                  }
                 
                />
              </Search>

              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                

                <Box sx={{ marginLeft: 0 }}></Box>

                


                <Box sx={{ marginLeft: 1 }}></Box>

                <Button type="submit" onClick={archive}
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3, mb: 3, bgcolor: '#fafafa', color: '#3c3c3c', boxShadow: 'none',
                    '&:hover': {
                      bgcolor: '#f5f5f5', // Change to the desired hover color
                    },
                  }}>
                  ARCHIVE
                </Button>




              </Box>



              <Avatar alt={localStorage.getItem('savedUsername')} onClick={ProfileClick} src={logo} sx={{ marginLeft: { sm: 2, xs: 7 } , cursor:"pointer" }} variant="square" />


              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="black"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>

          {renderMobileMenu}
          {renderMenu}
        </Box>

      </React.Fragment>

    </ThemeProvider>
  );
}
