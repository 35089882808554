import Header from "../Headers/Header";
import Side from "../SideBar2";
import TextField from "@mui/material/TextField";

import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import {
  useMediaQuery,
  createTheme,
  ThemeProvider,
  Stack,
} from "@mui/material";
import { useNavigate, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardActions,
  Fab,
  CardContent,
  useTheme,
  Divider,
  Button,
  CardMedia,
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Footer from "../footer";

const theme = createTheme();

function MainPage(props) {
  //const isAuthenticated = useAuth();
  const navigate = useNavigate();
  //const storedToken = localStorage.getItem('token');
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);
  const [RequestData, setRequestData] = useState([]);
  const [selectedButton, setSelectedButton] = useState("");
  const [type, setType] = React.useState("");
  const [keyword, setKeyword] = React.useState("");
  const [date, setDate] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [date2, setDate2] = React.useState("");

  const handleChange1 = (event) => {
    setKeyword(event.target.value);
  };

  const handleChange3 = (event) => {
    setTag(event.target.value);
  };

  const handleChange2 = (event) => {
    setDate(event.target.value);
  };

  const handleChange4 = (event) => {
    setSelectedButton(event.target.value);
    setType(event.target.value);
  };

  const handleChange5 = (event) => {
    setDate2(event.target.value);
  };

  const handleLearnMoreClick = (id) => {
    //console.log('Clicked "Learn More" for card with ID:', id);
  
    localStorage.setItem("clickedRequestId", id);
    navigate("/socialdata");
  };

  const handleadvance = (event) => {
    if (!date) {
      setDate("2024-01-01");
    }
    if (!date2) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1;
      month = month < 10 ? "0" + month : month; // Add leading zero if needed
      let day = currentDate.getDate();
      day = day < 10 ? "0" + day : day; // Add leading zero if needed
      var datef = `${year}-${month}-${day}`;
      setDate2(datef);
    }

    console.log(date2);
    fetch("https://server.facter.pk/advancesearch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ keyword, type, tag, date, date2 }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setRequestData(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error making fetch request:", error);
      });
  };

  useEffect(
    () => {
      window.scrollTo(0, 0);

      const search = localStorage.getItem("search");

      fetch("https://server.facter.pk/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ search }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setRequestData(data);
        })
        .catch((error) => {
          console.error("Error making fetch request:", error);
        });
    },
    [localStorage.getItem("search")],
    []
  );

  var currentDate = new Date();
  currentDate = currentDate.toDateString();
  return (
    <Box>
      <Header />

      <Stack direction={"column"} spacing={2} justifyContent={"center"}>
        <Box
          ml={0}
          flex={4}
          paddingY="5%"
          paddingX="10%"
          sx={{ height: "100vh" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "2%",
            }}
          >
            <Typography
              fontFamily="Libre Franklin"
              gutterBottom
              color="gray"
              component="div"
              marginRight="2%"
              fontWeight="bold"
              fontSize="10px"
            >
              Filters By Type:
            </Typography>
            <Button
              onClick={handleChange4}
              value="Suspected"
              sx={{
                backgroundColor:
                  selectedButton === "Suspected" ? "darkgray" : "black",
                color: "white",
                marginRight: "10px",
                padding: "5px 10px",
                fontSize: "12px",
                minWidth: "100px",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "Suspected" ? "gray" : "darkgray",
                },
              }}
            >
              Suspected
            </Button>
            <Button
              onClick={handleChange4}
              value="Misinformation (Documented & Fact Check by MMfD"
              sx={{
                backgroundColor:
                  selectedButton ===
                  "Misinformation (Documented & Fact Check by MMfD"
                    ? "darkgray"
                    : "black",
                color: "white",
                marginRight: "10px",
                padding: "5px 10px",
                fontSize: "12px",
                minWidth: "100px",
                "&:hover": {
                  backgroundColor:
                    selectedButton ===
                    "Misinformation (Documented & Fact Check by MMfD"
                      ? "gray"
                      : "darkgray",
                },
              }}
            >
              MisInformation
            </Button>
            <Button
              onClick={handleChange4}
              value="Verified/Published Fact-Checked Information"
              sx={{
                backgroundColor:
                  selectedButton ===
                  "Verified/Published Fact-Checked Information"
                    ? "gray"
                    : "black",
                color: "white",
                marginRight: "10px",
                padding: "5px 10px",
                fontSize: "12px",
                minWidth: "100px",
                "&:hover": {
                  backgroundColor:
                    selectedButton ===
                    "Verified/Published Fact-Checked Information"
                      ? "gray"
                      : "darkgray",
                },
              }}
            >
              Verified
            </Button>
            <Button
              onClick={handleChange4}
              value="Misleading Content/Mal-information"
              sx={{
                backgroundColor:
                  selectedButton === "Misleading Content/Mal-information"
                    ? "gray"
                    : "black",
                color: "white",
                marginRight: "10px",
                padding: "5px 10px",
                fontSize: "12px",
                minWidth: "100px",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "Misleading Content/Mal-information"
                      ? "gray"
                      : "darkgray",
                },
              }}
            >
              Misleading
            </Button>
            <Button
              onClick={handleChange4}
              value="Hate speech/Incitement"
              sx={{
                backgroundColor:
                  selectedButton === "Hate speech/Incitement"
                    ? "gray"
                    : "black",
                color: "white",
                marginRight: "10px",
                padding: "5px 10px",
                fontSize: "12px",
                minWidth: "100px",
                "&:hover": {
                  backgroundColor:
                    selectedButton === "Hate speech/Incitement"
                      ? "gray"
                      : "darkgray",
                },
              }}
            >
              Hate Speech
            </Button>
            <Box sx={{ flexGrow: 1 }} />{" "}
            {/* This box takes up remaining space */}
            <TextField
              onChange={handleChange2}
              id="filled-basic"
              label="From : yy-mm-dd"
              placeholder="From"
              variant="filled"
            />
            <TextField
              onChange={handleChange5}
              id="filled-basic"
              label="To : yy-mm-dd"
              variant="filled"
              placeholder="To"
            />
          </Box>
          <Divider />

          <Box
            sx={{
              paddingTop: "2%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "2%",
            }}
          >
            <Button
              value=""
              onClick={handleChange4}
              sx={{
                backgroundColor: selectedButton === "" ? "gray" : "black",
                color: "white",
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: selectedButton === "" ? "gray" : "darkgray",
                },
              }}
            >
              Remove All Filters
            </Button>
            <Box sx={{ flexGrow: 1 }} />{" "}
            {/* This box takes up remaining space */}
            <TextField
              onChange={handleChange1}
              variant="outlined"
              placeholder="Search..."
              size="small"
              sx={{ width: "200px" }} // Adjust width as needed
            />
            <Button
              onClick={handleadvance}
              sx={{
                backgroundColor: "black",
                color: "white",
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: "darkgray",
                },
              }}
            >
              Search
            </Button>
          </Box>

          {RequestData.length > 0 && (
            <Box
              bgcolor="black"
              sx={{
                justifyContent: "space-between",
                display: "flex",
                direction: "row",
                padding: "0.8%",
                marginTop: "3%",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
                color: "white",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Georgia",
                  marginLeft: "2%",
                }}
              >
                Title
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Georgia",
                  marginRight: "3%",
                }}
              >
                Category
              </Typography>
            </Box>
          )}

          {RequestData.map((item) => (
            <Card
              onClick={() => handleLearnMoreClick(item.id)}
              sx={{
                width: { xs: "100%", sm: "100%", lg: "100%" },
                ml: { xs: 0, sm: 0 },
                mt: { xs: 0, sm: 0 },
                background: "White",
                "&:hover": { cursor: "pointer" },
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    fontFamily="Georgia"
                    gutterBottom
                    component="div"
                    fontSize="18px"
                  >
                    Description: {item.title}
                  </Typography>

                  <Typography
                    fontFamily="Libre Franklin"
                    gutterBottom
                    color="gray"
                    component="div"
                    marginRight="2%"
                    paddingLeft="3%"
                    fontWeight="bold"
                    fontSize="15px"
                  >
                    {item.type === "Misleading Content/Mal-information"
                      ? "Misleading"
                      : item.type === "Misinformation (Documented & Fact Check by MMfD"
                      ? "MisInformation"
                       : item.type === "Verified/Published Fact-Checked Information"
                      ? "Verified"
                       : item.type === "Suspected"
                      ? "Suspected"
                       : item.type === "Hate speech/Incitement"
                      ? "Hate-Speech"
                      : item.type}
                  </Typography>
                </Box>

                <Typography
                  mt={1}
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontFamily: "Libre Franklin",
                    maxWidth: "700px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontWeight: "bold",
                  }}
                >
                  {item.added_datetime.split('T')[0]}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        <Footer />
      </Stack>
    </Box>
  );
}

export default MainPage;
