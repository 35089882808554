import Header from "./Headers/NavBar";
import Header2 from "./Headers/NavBar2";
import Side from "./SideBar2";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";

import Box from "@mui/material/Box";
import TopButton from "./TopCard";
import {
  useMediaQuery,
  createTheme,
  CardActions,
  ThemeProvider,
  Stack,
  Typography,
  Button,
  TextField,
  CardContent,
  Card,
  Radio,
  FormControlLabel,
  selectedOption,
  RadioGroup,
  FormControl,
  Fab,
} from "@mui/material";

import { useNavigate, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

const theme = createTheme();
function MainPage(props) {
  //const isAuthenticated = useAuth();
  const navigate = useNavigate();
  const storedToken = localStorage.getItem("token");
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [categories, setCategory] = useState([]);
  const [author, setAuthor] = useState([]);
  const [monthCount,setMonth]=useState(0);
  const [monthCountP,setMonthP]=useState(0);
  const [weekCount,setWeek] = useState(0);
  React.useEffect(() => {

    fetch("https://server.facter.pk/validateToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("TOKEN"),
       
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
          
        }
    
        return response.json();
      })
      .then((data) => {
         
          if (data.valid === false){
               navigate("/")
          }
          
      })
      .catch((error) => {
      
        console.error("Error making POST request:", error);
      });
    fetch("https://server.facter.pk/platformsCount") // Assuming the API is served from the same domain
      .then((response) => response.json())
      .then((data) => {
        setPlatforms(data); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    fetch("https://server.facter.pk/categoryCount") // Assuming the API is served from the same domain
      .then((response) => response.json())
      .then((data) => {
        setCategory(data); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });


      fetch("https://server.facter.pk/authorCount") // Assuming the API is served from the same domain
      .then((response) => response.json())
      .then((data) => {
        setAuthor(data); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

      fetch("https://server.facter.pk/monthCount") // Assuming the API is served from the same domain
      .then((response) => response.json())
      .then((data) => {
        setMonth(data.count); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

      fetch("https://server.facter.pk/monthCountPerv") // Assuming the API is served from the same domain
      .then((response) => response.json())
      .then((data) => {
        setMonthP(data.count); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

      fetch("https://server.facter.pk/weekCount") // Assuming the API is served from the same domain
      .then((response) => response.json())
      .then((data) => {
        setWeek(data.count); // Update state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });


    setTokenCheckComplete(true);
  });

  return isTokenCheckComplete ? (
    <Box>
      <Header />
      <Header2 />
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent={"center"}
        marginTop={20}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography>Platforms</Typography>
                <BarChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: [
                        "Facebook",
                        "TikTok",
                        "Twitter",
                        "YouTube",
                        "Others",
                      ], // Labels for the bars
                    },
                  ]}
                  series={[
                    {
                      data: [
                        platforms[0]?.count || 0,
                        platforms[2]?.count || 0,
                        (platforms[3]?.count || 0) +
                          (platforms[4]?.count || 0) +
                          (platforms[5]?.count || 0) +
                          (platforms[6]?.count || 0),
                        platforms[7]?.count || 0,
                        platforms[1]?.count || 0,
                      ],
                    }, // Value for "Facebook"
                  ]}
                  width={500}
                  height={300}
                />
              </CardContent>
            </Card>

            <Card sx={{ minWidth: 500 }}>
              <CardContent>
                <Typography>Category</Typography>
                <PieChart
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value:
                            (categories[1]?.count || 0) +
                            (categories[2]?.count || 0) +
                            (categories[3]?.count || 0),
                          label: "MisInformation",
                        },
                        {
                          id: 1,
                          value:
                            (categories[6]?.count || 0) +
                            (categories[7]?.count || 0),
                          label: "Suspected Information",
                        },
                        {
                          id: 2,
                          value:
                            (categories[8]?.count || 0) +
                            (categories[9]?.count || 0) +
                            (categories[10]?.count || 0),
                          label: "Verified Information",
                        },
                        {
                          id: 3,
                          value:
                            (categories[4]?.count || 0) +
                            (categories[5]?.count || 0),
                          label: "Misleading Content",
                        },
                        {
                          id: 4,
                          value: categories[0]?.count || 0,
                          label: "Hate Speech",
                        },
                      ],
                    },
                  ]}
                  width={600}
                  height={200}
                />
              </CardContent>
            </Card>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "20px",marginTop:"40px",marginBottom:"100px" }}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography>Monitor Evaluation Weekly</Typography>
                <BarChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: [
                        "Saira",
                        "Shehla",
                        "Huzaifa",
                       
                      ], // Labels for the bars
                    },
                  ]}
                  series={[
                    {
                      data: [
                        
                        author[1]?.count || 0,
                        author[2]?.count || 0,
                        author[0]?.count || 0
                      ],

                      color: ['red'],
                    
                    }, // Value for "Facebook"
                  ]}
                  width={500}
                  height={300}
                />
              </CardContent>
            </Card>


            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography>Frequency Of Posts</Typography>
                <BarChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: [
                        "Previous Month",
                        "Current Month",
                        "Previous Week",
                        
                       
                      ], // Labels for the bars
                    },
                  ]}
                  series={[
                    {
                      data: [
                        
                        monthCountP,monthCount,weekCount
                      ],

                      color: ['orange'],
                    
                    }, // Value for "Facebook"
                  ]}
                  width={500}
                  height={300}
                   barLabel="value"
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </Stack>
    </Box>
  ) : null;
}

export default MainPage;
