import Header from "../Headers/Header";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Side from "../SideBar2";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import {
  useMediaQuery,
  createTheme,
  ThemeProvider,
  Stack,
} from "@mui/material";
import { useNavigate, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardActions,
  Fab,
  CardContent,
  useTheme,
  Divider,
  Button,
  CardMedia,
} from "@mui/material";
import Imran from "../image/imranKhan.jpeg";
import CYBER from "../image/cyber.jpeg";
import POLICE from "../image/police.jpeg";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Footer from "../footer";
import view from "../viewmore/view";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";

import Grid from "@mui/material/Grid";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const theme = createTheme({
  typography: {
    fontFamily: "Georgia", // Specify Georgia as the font family
  },
});

function MainPage(props) {
  //const isAuthenticated = useAuth();
  const navigate = useNavigate();
  //const storedToken = localStorage.getItem('token');
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);

  //to handle the add request
  const addRequest = () => {
    navigate("/addrequest");
  };

  //to show the  request
  const showRequest = () => {
    navigate("/showrequest");
  };

  //to show the  request
  const addJob = () => {
    navigate("/addjob");
  };

  const handleLearnMoreClick = (id) => {
    //console.log('Clicked "Learn More" for card with ID:', id);
    localStorage.setItem("clickedRequestId", id);
    navigate("/socialdata");
  };

  var currentDate = new Date();
  currentDate = currentDate.toDateString();

  const [suspected, setData1] = useState([]);
  const [suspectedImage, setData2] = useState([]);

  const [mis, setData3] = useState([]);
  const [misImage, setData4] = useState([]);

  const [verify, setData5] = useState([]);
  const [verifyImage, setData6] = useState([]);

  const [mislead, setData7] = useState([]);
  const [misleadImage, setData8] = useState([]);

  const [hate, setData9] = useState([]);
  const [hateImage, setData10] = useState([]);

  const [priority, setpriority] = useState([]);
  const [priorityimage, setpriorityImage] = useState([]);

  useEffect(() => {

    fetch("https://server.facter.pk/validateToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("TOKEN"),
       
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        
        return response.json();
      })
      .then((data) => {
         
          if (data.valid === false){
               navigate("/")
          }
          
      })
      .catch((error) => {
      
        console.error("Error making POST request:", error);
      });
   
    // Function to fetch data from the server
    fetch("https://server.facter.pk/suspected", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData1(data);
        console.log("sdasdasds");

        fetch("https://server.facter.pk/suspectedImage", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // Assuming data is an array of objects with 'filename' and 'base64image' properties
            setData2(data);
          })
          .catch((error) => {
            console.error("Error making fetch request:", error);
          });
      })
      .catch((error) => {
        console.error("Error making fetchdddddd request:", error);
      });

    //------------------------------------------------------------------------------------------//

    fetch("https://server.facter.pk/misinformation", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData3(data);

        fetch("https://server.facter.pk/misinformationImage", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // Assuming data is an array of objects with 'filename' and 'base64image' properties
            setData4(data);
          })
          .catch((error) => {
            console.error("Error making fetch request:", error);
          });
      })
      .catch((error) => {
        console.error("Error making fetchdddddd request:", error);
      });

    //------------------------------------------------------------------------------------------//

    fetch("https://server.facter.pk/verified", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData5(data);

        fetch("https://server.facter.pk/verifiedImage", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // Assuming data is an array of objects with 'filename' and 'base64image' properties
            setData6(data);
          })
          .catch((error) => {
            console.error("Error making fetch request:", error);
          });
      })
      .catch((error) => {
        console.error("Error making fetchdddddd request:", error);
      });

    //------------------------------------------------------------------------------------------//

    fetch("https://server.facter.pk/misleading", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData7(data);

        fetch("https://server.facter.pk/misleadingImage", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // Assuming data is an array of objects with 'filename' and 'base64image' properties
            setData8(data);
          })
          .catch((error) => {
            console.error("Error making fetch request:", error);
          });
      })
      .catch((error) => {
        console.error("Error making fetchdddddd request:", error);
      });

    //------------------------------------------------------------------------------------------//

    fetch("https://server.facter.pk/hate", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData9(data);

        fetch("https://server.facter.pk/hateImage", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // Assuming data is an array of objects with 'filename' and 'base64image' properties
            setData10(data);
          })
          .catch((error) => {
            console.error("Error making fetch request:", error);
          });
      })
      .catch((error) => {
        console.error("Error making fetchdddddd request:", error);
      });

    fetch("https://server.facter.pk/priority", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setpriority(data);

        fetch("https://server.facter.pk/priorityImage", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // Assuming data is an array of objects with 'filename' and 'base64image' properties
            setpriorityImage(data);
          })
          .catch((error) => {
            console.error("Error making fetch request:", error);
          });
      })
      .catch((error) => {
        console.error("Error making fetchdddddd request:", error);
      });

    //------------------------------------------------------------------------------------------//
  }, []);

  return (
    <Box display={"block"}>
      <Header />

      <Stack direction={"column"} spacing={0} justifyContent={"center"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "col",
            justifyContent: "space-between",

            paddingY: "2%",
          }}
        >
          <Box
            sx={{
              width: {xs:"70",sm:"55%"},
              marginLeft: {xs:"7%",sm:"10%"},
              paddingY: "0.3%",
              paddingX: "0.3%",
            }}
          >
            <Box sx={{ paddingY: "2%" }}>
              <Divider sx={{ borderWidth: "1.5px" }} />
              <Link
                to="/view"
                onClick={() =>
                  localStorage.setItem(
                    "search",
                    "Misinformation (Documented & Fact Check by MMfD"
                  )
                }
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    marginTop: "5px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "Gilda Display",
                  }}
                >
                  MISINFORMATION
                </Typography>
              </Link>
              <Box
                sx={{
                  display: {xs:"none", sm:"flex"},
                  flexDirection: "row",
                  marginTop: "1%",
                }}
              >
                <Box sx={{ width: "100%", height: "200px" }}>
                  <Card
                    sx={{
                      display: {xs:"none", sm:"flex"},
                      width: "100%",
                      height: "80%",
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                    onClick={() => handleLearnMoreClick(mis[0].id)}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <CardContent>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              fontFamily: "Georgia",
                            }}
                          >
                            {mis?.[0]?.title || "Loading..."}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Libre Franklin",
                            }}
                          >
                           
                         
                            <br />
                            <br />
                            {mis?.[0]?.added_datetime.split("T")[0] ||
                              "Loading..."}
                            <br />
                            Misinformation (Fact Check by MMFD)
                          </Typography>
                        </CardContent>
                      </Grid>
                      <Grid item xs={4}>
                        <LazyLoadImage
                          
                          src={`data:image/png;base64,${
                            misImage[0]?.base64Data || ""
                          }`}
                          alt="Lazy loaded example"
                          effect="blur"
                          placeholderSrc={Imran}
                          style={{
                            marginLeft: "50%",
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              </Box>

              <Box
                sx={{
                  display: {xs:"block", sm:"flex"},
                  flexDirection: {xs:"block",sm:"row"},
                  
                  justifyContent: "space-around",
                  gap: "20px", // Adjust the gap value as needed
                  padding: "0.2%", // Optional padding for spacing around the cards
                }}
              >
                {mis.slice(1).map((item, index) => (
                  <Card
                    key={item}
                    onClick={() => handleLearnMoreClick(item.id)}
                    sx={{
                      width: "90%",
                      marginBottom:{xs:"2%",sm:"0%"},
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                   
                      <LazyLoadImage
                        alt="Lazy loaded example"
                        effect="blur"
                        placeholderSrc={Imran}
                        src={`data:image/png;base64,${
                          misImage[index + 1]?.base64Data || ""
                        }`}
                       
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                 
                    <CardContent>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "Georgia",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        sx={{ fontSize: "10px", fontFamily: "Libre Franklin" }}
                      >
                        <br />
                        {item.added_datetime.split("T")[0]}
                        <br />
                        Misinformation (Fact Check by MMFD)
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>

            <Box sx={{ paddingY: "2%" , marginTop:"3%" }}>
              <Divider sx={{ borderWidth: "1.5px" }} />
              <Link
                to="/view"
                onClick={() => localStorage.setItem("search", "Suspected")}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    marginTop: "5px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "Gilda Display",
                  }}
                >
                  SUSPECTED INFORMATION
                </Typography>
              </Link>
              <Box
                sx={{
                  display: {xs:"none", sm:"flex"},
                  flexDirection: "row",
                  marginTop: "1%",
                }}
              >
                <Box sx={{ width: "100%", height: "200px" }}>
                  <Card
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "80%",
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                    onClick={() => handleLearnMoreClick(suspected[0].id)}
                  >
                    <Grid container>
                      <Grid item xs={4}>
                      <LazyLoadImage
                        alt="Lazy loaded example"
                        effect="blur"
                        src={`data:image/png;base64,${
                          suspectedImage[0]?.base64Data || ""
                        }`}
                        placeholderSrc={Imran}
                        style={{
                          marginTop:{xs:"8%",xl:"0%"},
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                 
                      </Grid>
                      <Grid item xs={8}>
                        <CardContent>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              fontFamily: "Georgia",
                            }}
                          >
                            {suspected?.[0]?.title || "Loading..."}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Libre Franklin",
                            }}
                          >
                            
                            <br />
                            <br />
                            <br />
                            {suspected?.[0]?.added_datetime?.split("T")[0] ||
                              "Loading..."}
                            <br />
                            Suspected Misinformation
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              </Box>

              <Box
                sx={{
                  display: {xs:"block",sm:"flex"},
                  flexDirection: "row",
                  marginTop: "2%",
                  justifyContent: "space-around",
                  gap: "20px", // Adjust the gap value as needed
                  padding: "0.2%", // Optional padding for spacing around the cards
                  
                }}
              >
                {suspected.slice(1).map((item, index) => (
                  <Card
                    key={item}
                    onClick={() => handleLearnMoreClick(item.id)}
                    sx={{
                      width: "90%",
                      marginBottom:{xs:"2%",sm:"0%"},
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                     <LazyLoadImage
                        alt="Lazy loaded example"
                        effect="blur"
                        placeholderSrc={Imran}
                        src={`data:image/png;base64,${
                          suspectedImage[index + 1]?.base64Data || ""
                        }`}
                       
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    <CardContent>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "Georgia",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        sx={{ fontSize: "10px", fontFamily: "Libre Franklin" }}
                      >
                        <br />
                        {item.added_datetime.split("T")[0]}
                        <br />
                        Suspected Misinformation
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>

            <Box sx={{ paddingY: "2%", marginTop:"3%" }}>
              <Divider sx={{ borderWidth: "1.5px" }} />
              <Link
                to="/view"
                onClick={() =>
                  localStorage.setItem(
                    "search",
                    "Misleading Content/Mal-information"
                  )
                }
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    marginTop: "5px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "Gilda Display",
                  }}
                >
                  MISLEADING CONTENT
                </Typography>
              </Link>
              <Box
                sx={{
                  display: {xs:"none" , sm:"flex"},
                  flexDirection: "row",
                  marginTop: "1%",
                }}
              >
                <Box sx={{ width: "100%", height: "200px" }}>
                  <Card
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "80%",
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                    onClick={() => handleLearnMoreClick(mislead[0].id)}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <CardContent>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              fontFamily: "Georgia",
                            }}
                          >
                            {mislead?.[0]?.title || "Loading..."}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Libre Franklin",
                            }}
                          >
                           
                            <br />
                            <br />
                            <br />
                            {mislead?.[0]?.added_datetime.split("T")[0] ||
                              "Loading..."}
                            <br />
                            Misleading Content
                          </Typography>
                        </CardContent>
                      </Grid>
                      <Grid item xs={4}>
                      <LazyLoadImage
                          
                          src={`data:image/png;base64,${
                            misleadImage[0]?.base64Data || ""
                          }`}
                          alt="Lazy loaded example"
                          effect="blur"
                          placeholderSrc={Imran}
                          style={{
                           
                            marginLeft: "50%",
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              </Box>

              <Box
                sx={{
                  display: {xs:"block",sm: "flex"},
                  flexDirection: "row",
               
                  justifyContent: "space-around",
                  gap: "20px", // Adjust the gap value as needed
                  padding: "0.2%", // Optional padding for spacing around the cards
                }}
              >
                {mislead.slice(1).map((item, index) => (
                  <Card
                    key={item}
                    onClick={() => handleLearnMoreClick(item.id)}
                    sx={{
                      width: "90%",
                      cursor: "pointer",
                      marginBottom:{xs:"2%",sm:"0%"},
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                     <LazyLoadImage
                        alt="Lazy loaded example"
                        effect="blur"
                        placeholderSrc={Imran}
                        src={`data:image/png;base64,${
                          misleadImage[index + 1]?.base64Data || ""
                        }`}
                       
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    <CardContent>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "Georgia",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        sx={{ fontSize: "10px", fontFamily: "Libre Franklin" }}
                      >
                        <br />
                        {item.added_datetime.split("T")[0]}
                        <br />
                        Misleading Content
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>

            <Box sx={{ paddingY: "2%" ,marginTop:"3%" }}>
              <Divider sx={{ borderWidth: "1.5px" }} />
              <Link
                to="/view"
                onClick={() =>
                  localStorage.setItem(
                    "search",
                    "Verified/Published Fact-Checked Information"
                  )
                }
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    marginTop: "5px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "Gilda Display",
                  }}
                >
                  VERIFIED INFORMATION
                </Typography>
              </Link>
              <Box
                sx={{
                  display: {xs:"none", sm:"flex"},
                  flexDirection: "row",
                  marginTop: "1%",
                }}
              >
                <Box sx={{ width: "100%", height: "200px" }}>
                  <Card
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "80%",
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                    onClick={() => handleLearnMoreClick(verify[0].id)}
                  >
                    <Grid container>
                      <Grid item xs={4}>
                      <LazyLoadImage
                        alt="Lazy loaded example"
                        effect="blur"
                        src={`data:image/png;base64,${
                          verifyImage[0]?.base64Data || ""
                        }`}
                        placeholderSrc={Imran}
                        style={{
                        
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                      </Grid>
                      <Grid item xs={8}>
                        <CardContent>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              fontFamily: "Georgia",
                            }}
                          >
                            {verify?.[0]?.title || "Loading..."}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Libre Franklin",
                            }}
                          >
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            {verify?.[0]?.added_datetime?.split("T")[0] ||
                              "Loading..."}
                            <br />
                            Verified Infomation
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              </Box>

              <Box
                sx={{
                  display: {xs:"block", sm:"flex"},
                  flexDirection: "row",
                 
                  justifyContent: "space-around",
                  gap: "20px", // Adjust the gap value as needed
                  padding: "0.2%", // Optional padding for spacing around the cards
                }}
              >
                {verify.slice(1).map((item, index) => (
                  <Card
                    key={item}
                    onClick={() => handleLearnMoreClick(item.id)}
                    sx={{
                      width: "90%",
                      marginBottom:{xs:"2%",sm:"0%"},
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                     <LazyLoadImage
                        alt="Lazy loaded example"
                        effect="blur"
                        placeholderSrc={Imran}
                        src={`data:image/png;base64,${
                          verifyImage[index + 1]?.base64Data || ""
                        }`}
                       
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    <CardContent>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "Georgia",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        sx={{ fontSize: "10px", fontFamily: "Libre Franklin" }}
                      >
                        <br />
                        {item.added_datetime.split("T")[0]}
                        <br />
                        Verified Content
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>

            <Box sx={{ paddingY: "2%",marginTop:"3%" }}>
              <Divider sx={{ borderWidth: "1.5px" }} />
              <Link
                to="/view"
                onClick={() =>
                  localStorage.setItem("search", "Hate speech/Incitement")
                }
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    marginTop: "5px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "Gilda Display",
                  }}
                >
                  HATE SPEECH
                </Typography>
              </Link>
              <Box
                sx={{
                  display: {xs:"none" , sm:"flex"},
                  flexDirection: "row",
                  marginTop: "1%",
                }}
              >
                <Box sx={{ width: "100%", height: "200px" }}>
                  <Card
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "80%",
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                    onClick={() => handleLearnMoreClick(hate[0].id)}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <CardContent>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              fontFamily: "Georgia",
                            }}
                          >
                            {hate?.[0]?.title || "Loading..."}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            sx={{
                              fontSize: "10px",
                              fontFamily: "Libre Franklin",
                            }}
                          >
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            {hate?.[0]?.added_datetime.split("T")[0] ||
                              "Loading..."}
                            <br />
                            Hate Speech
                          </Typography>
                        </CardContent>
                      </Grid>
                      <Grid item xs={4}>
                      <LazyLoadImage
                          
                          src={`data:image/png;base64,${
                            hateImage[0]?.base64Data || ""
                          }`}
                          alt="Lazy loaded example"
                          effect="blur"
                          placeholderSrc={Imran}
                          style={{
                           
                            marginLeft: "50%",
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              </Box>

              <Box
                sx={{
                  display:{xs:"block" ,sm: "flex"},
                  flexDirection: "row",
                
                  justifyContent: "space-around",
                  gap: "20px", // Adjust the gap value as needed
                  padding: "0.2%", // Optional padding for spacing around the cards
                }}
              >
                {hate.slice(1).map((item, index) => (
                  <Card
                    key={item}
                    onClick={() => handleLearnMoreClick(item.id)}
                    sx={{
                      width: "90%",
                      cursor: "pointer",
                      marginBottom:{xs:"2%",sm:"0%"},
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <LazyLoadImage
                        alt="Lazy loaded example"
                        effect="blur"
                        placeholderSrc={Imran}
                        src={`data:image/png;base64,${
                          hateImage[index + 1]?.base64Data || ""
                        }`}
                       
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    <CardContent>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "Georgia",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        sx={{ fontSize: "10px", fontFamily: "Libre Franklin" }}
                      >
                        <br />
                        {item.added_datetime.split("T")[0]}
                        <br />
                        Hate Speech
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display:{xs:"none", sm:"block"},
              width: "20%",
              height: {sm:"20%", md:"40%"},
              marginRight: "10%",
              paddingY: "1.4%",
              position: "sticky",
              top: 0,
              zIndex: 1000,
            }}
          >
            <Divider sx={{ borderWidth: "1.5px" }} />
            <Typography
              sx={{
                marginTop: "5px",
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: "Gilda Display",
              }}
            >
              Top priority
            </Typography>

            <Box
              sx={{
                marginTop: "2%",
               
                justifyContent: "space-around",
                gap: "20px", // Adjust the gap value as needed
                padding: "0.2%", // Optional padding for spacing around the cards
                overflowY: "auto", // Enables vertical scrollbar
                maxHeight: "550px" // Set a maximum height to trigger the scrollbar
              }}
            >
              {priority.slice(0, 3).map((item, index) => (
                <Card
                  key={item}
                  onClick={() => handleLearnMoreClick(item.id)}
                  sx={{
                    width: "90%",
                    cursor: "pointer",
                    marginLeft:"3%",
                    marginTop: "10px",
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <LazyLoadImage
                        alt="Lazy loaded example"
                        effect="blur"
                        src={`data:image/png;base64,${
                          priorityimage[index]?.base64Data || ""
                        }`}
                        placeholderSrc={Imran}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}

                        placeholderStyle={{
                          height: "200px", // Set the desired height here
                          width: "100%",
                        }}
                      />
                  <CardContent>
                    <Typography
                      component="div"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Georgia",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{ fontSize: "10px", fontFamily: "Libre Franklin" }}
                    >
                      <br />
                      {item.added_datetime.split("T")[0]}
                      <br />
                      {item.category}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>

        <Footer />
      </Stack>
    </Box>
  );
}

export default MainPage;
