
import Header from './Headers/Header'
import Side from './SideBar2'
import Box from '@mui/material/Box';
import React ,{useState,Component,useEffect,useRef } from 'react';
import styled from 'styled-components';
import { useMediaQuery, createTheme, ThemeProvider, Stack ,Typography,Button,TextField,CardContent,Card,Radio,FormControlLabel,selectedOption,RadioGroup,FormControl,Fab} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Wrapper = styled.div`
 margin-top: 16px;
 margin-bottom: 32px;
 display: flex;
 flex-wrap: wrap;
 justify-content: space-around ;
`;

const WrapperLeft = styled.div`
width: 90%;
position: relative;
`;
const WrapperRight = styled.div`
width: 48%;
`;
const theme = createTheme();

function EditorialForm(props) {

  const navigate = useNavigate();//for navigation
  const storedToken = localStorage.getItem('token');//store token
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(false);
  
  const [text, setText] = useState('');// storing the text written on the blog
  const [selectedFile, setSelectedFile] = useState(null);//storing the thumbnail file
  
  // Event handler function for the blog
  const handleChange = (value) => {
    setText(value);
  };

  

    //token cheking fucntion
    React.useEffect(() => {

      fetch("https://server.facter.pk/validateToken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: localStorage.getItem("TOKEN"),
         
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
            
          }
      
          return response.json();
        })
        .then((data) => {
           
            if (data.valid === false){
                 navigate("/")
            }
            
        })
        .catch((error) => {
        
          console.error("Error making POST request:", error);
        });
  
  
      //if (!storedToken) {
        // No token found, navigate to login
        //navigate('/login');
      //} else {
        //console.log('sdds');
        //const parsedToken =  JSON.parse(storedToken);
        //console.log(parsedToken.expiration,'dd');
        //console.log(Date.now());
        //if (parsedToken.expiration < Date.now()) {
         
          // Token has expired, clear it from local storage and navigate to login
          //localStorage.removeItem('token');
          //navigate('/login');
  
  
        //}
     // }
  
      setTokenCheckComplete(true);
  
  
  
    });


    //blog submission button
    const submitBlog = async () => {
      
     // console.log('Button clicked! Text:', text);
    

      try {
        const apiUrl = 'https://server.facter.pk/submitBlog'; // Replace with your actual API endpoint
    
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any additional headers if needed, e.g., authorization token
          },
          body: JSON.stringify({
            text: text,
            
            // Include any other data you want to send to the server
          }),
        });
    
        if (!response.ok) {
          // Handle the error, maybe show an error message
          console.error('Failed to submit data:', response.statusText);
          return;
        }
    
        // Data successfully submitted
        console.log('Data submitted successfully');
        setOpen(true);
        setText('');
  
      } catch (error) {
        // Handle unexpected errors
       
        console.error('Error:', error.message);
      }

      
      
    };

    const [open, setOpen] = useState(false);

    
  
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };


  
      return isTokenCheckComplete ?(

       
       
        <Box >

            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity="success">
                      THE BLOG SUBMITTED SUCCESSFULLY
                    </Alert>
            </Snackbar>

            <Header/> 

            <Stack direction={'row'} spacing={2} justifyContent={'center'} >
 
           
                    <Box   ml={0} flex={4} p={2} pt={10} sx={{height: '100vh'  }} >
                        <Typography variant="h6" color="#acabab" fontFamily={'"Helvetica Neue"'} sx={{ml:{sm:8,sx:7}, fontWeight: 'bold', color:'#434343'}}>EDITORIAL DATA ADD FORM</Typography>
                            <Box sx={{ display:{xs:'block' , sm:'block' } ,backgroundColor: '#fafafa' , paddingTop:'50px',paddingBottom:'15px' , width:{sm:700,xs:300}, ml:{sm:8,xs:2},mt:2 ,mb:6 ,pb:15, border:'2px solid #dedede'}}>
                            <div className="App">
                            <header className="App-header">
                            
                            </header>
                            <Wrapper>
                            <WrapperLeft>
                          
                            <ReactQuill value={text}
                              style={{height: '500px'}}
                              modules={EditorialForm.modules}
                              onChange={handleChange} />
                            </WrapperLeft>
                           
                            </Wrapper>
                          </div>

                              

                                    



                            </Box>

                            <Box display='block'>
                            
                              
                              

                              <Fab variant="extended" onClick={submitBlog}  sx={{mb:8, ml:0 ,fontWeight: 'bold',backgroundColor: '#ff5722', color: '#121313' ,'&:hover': { color: '#ff5722', backgroundColor: '#e3e2e2', boxShadow: 'none', boxShadow: '0 0 0 2px #ff5722' }}}>
                                SUBMIT
                            </Fab>

                            </Box>


                    </Box>
                    
              <Side/>
              
            </Stack>

        </Box>
          
       
    ):null;

}


EditorialForm.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }],
    [{ 'color': [] }, { 'background': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['link', 'image'],
  ],
};

EditorialForm.formats = [
  'header', 'font', 'background', 'color', 'code', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent', 'script', 'align', 'direction',
  'link', 'image', 'code-block', 'formula', 'video'
];


export default EditorialForm;