import Header from "./NavBar";
import Header2 from "./NavBar2";
import Box from "@mui/material/Box";
import React, { useState, event } from "react";
import Footer from "../footer";
import {
  CardMedia,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  Stack,
  Typography,
  Button,
  TextField,
  CardContent,
  Card,
  Radio,
  FormControlLabel,
  selectedOption,
  RadioGroup,
  FormControl,
  Fab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AspectRatio } from "@mui/icons-material";
import reportHeader from "../logos/Header.PNG";
import reportFooter from "../logos/Footer.PNG";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
const theme = createTheme();

function EditorialForm(props) {
  const navigate = useNavigate(); //for navigation
  const storedToken = localStorage.getItem("token"); //store token
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(false);
  const [ReportList, setreportList] = useState([]);
  const [Report, setreport] = useState([]);
  const [currReport, setcurrReport] = useState("");

  //token cheking fucntion
  React.useEffect(() => {
    //if (!storedToken) {
    // No token found, navigate to login
    //navigate('/login');
    //} else {
    //console.log('sdds');
    //const parsedToken =  JSON.parse(storedToken);
    //console.log(parsedToken.expiration,'dd');
    //console.log(Date.now());
    //if (parsedToken.expiration < Date.now()) {

    // Token has expired, clear it from local storage and navigate to login
    //localStorage.removeItem('token');
    //navigate('/login');

    //}
    // }

    setTokenCheckComplete(true);

    const requestId = localStorage.getItem("clickedRequestId");

    fetch("https://server.facter.pk/reports", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setreport(data);
      })
      .catch((error) => {
        console.error("Error making fetch request:", error);
      });

    fetch("https://server.facter.pk/Allreports", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const lastElement = data.length;

        // Set currReport with the date without time
        setcurrReport(data[lastElement - 1].date);

        setreportList(data);
      })
      .catch((error) => {
        console.error("Error making fetch request:", error);
      });
  }, []);

  const handleDateSelect = (date, event) => {
    const selectedOption = date;

    setcurrReport(selectedOption);

    fetch("https://server.facter.pk/Selectedreport", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ currentDate: date }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setreport(data);
      })
      .catch((error) => {
        console.error("Error making fetch request:", error);
      });
  };

  return (
    <Box>
      <Header />
      <Header2 />

      <Stack direction={"column"} spacing={2} sx={{ height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            direction: "column",
            paddingTop: "5%",
            paddingBottom: "5.6%",
            marginBottom: "5%",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              height: "100%", // Adjust height as needed
              width: "10%",
              display: { xs: "none", sm: "flex" },
              flexDirection: "column", // Ensure scrolling is vertical
              alignItems: "start",
              justifyContent: "start",
              overflowY: "auto", // Enable vertical scrolling
              position: "sticky", // Make it sticky
              top: 0, // Adjust as needed
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Add shadow
            }}
          >
            <Typography
              sx={{
                mt: "40%",
                ml: 2,
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: "Gilda Display",
              }}
            >
              Older posts
            </Typography>

            <Box sx={{ mt: "5%", ml: 2, position: "sticky" }}>
              {ReportList.map((item, index) => (
                <Typography
                  sx={{
                    marginTop: "3%",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDateSelect(item.id)}
                >
                  {item.date}
                </Typography>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              marginY: "4%",
              padding: "0.5%",
             
              marginLeft: "13.5%",
              
              width: "59.5%",
              display: "block",
              alignItems: "start",
              justifyContent: "start",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Add shadow
            }}
          >
            <img src={reportHeader} alt="Your image description" />
            {Report.map((report, index) => (
              <div
                style={{ marginLeft: "50px" }}
                dangerouslySetInnerHTML={{ __html: report.report }}
              />
            ))}

            <img sx={{Bottom:"10%"}} src={reportFooter} alt="Your image description" />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}

export default EditorialForm;
