
import Header from '../Headers/Header'
import Side from '../SideBar2'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useMediaQuery, createTheme, ThemeProvider, Stack } from '@mui/material';
import { useNavigate, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardActions, Fab, CardContent, useTheme, Divider, Button, CardMedia } from "@mui/material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Footer from '../footer'
import { setDate } from 'date-fns';


const theme = createTheme();



function MainPage(props) {



    const [visiblePosts, setVisiblePosts] = useState(13); // Number of posts initially visible

    const loadMorePosts = () => {

        //setVisiblePosts(prev => prev + 3); // Increase the number of visible posts
        const skip = skipCount + 13;

        if(count[0]['COUNT(*)'] > skip){
        setskipCount(skip, () => {

            console.log(skipCount);
            const search = localStorage.getItem('search');
            setImageData([]);
            setRequestData([]);

            
            fetch('https://server.facter.pk/view1', {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({ search, skipCount })


            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setRequestData(data);

                    fetch('https://server.facter.pk/viewImage1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },

                        body: JSON.stringify({ search, skipCount })
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then(data => {
                            // Assuming data is an array of objects with 'filename' and 'base64image' properties
                            setImageData(data);
                            // Adjust timeZone if needed

                        })
                        .catch(error => {
                            console.error('Error making fetch request:', error);
                        });



                })
                .catch(error => {
                    console.error('Error making fetch request:', error);
                });

        });

    }

    };


    const back = () => {
        //setVisiblePosts(prev => prev + 3);
        const skip = skipCount - 13;

        if(skip>=0){
        setskipCount(skip, () => {

            console.log(skipCount);
            console.log(localStorage.getItem('search'));
            const search = localStorage.getItem('search');
            setImageData([]);
            setRequestData([]);


            fetch('https://server.facter.pk/view1', {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({ search, skipCount })


            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setRequestData(data);

                    fetch('https://server.facter.pk/viewImage1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },

                        body: JSON.stringify({ search, skipCount })
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then(data => {
                            // Assuming data is an array of objects with 'filename' and 'base64image' properties
                            setImageData(data);
                            // Adjust timeZone if needed

                        })
                        .catch(error => {
                            console.error('Error making fetch request:', error);
                        });



                })
                .catch(error => {
                    console.error('Error making fetch request:', error);
                });

        });

    }

    };
    //const isAuthenticated = useAuth();
    const navigate = useNavigate();
    //const storedToken = localStorage.getItem('token');
    const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);
    const [RequestData, setRequestData] = useState([]);
    const [Image, setImageData] = useState([]);
    const [skipCount, setskipCount] = useState(0);
    const [count, setCount] = useState(0);



    const handleLearnMoreClick = (id) => {

        //console.log('Clicked "Learn More" for card with ID:', id);
        localStorage.setItem('clickedRequestId', id);
        navigate('/socialdata');
    };

    useEffect(() => {

        fetch("https://server.facter.pk/validateToken", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: localStorage.getItem("TOKEN"),
             
            }),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
                
              }
          
              return response.json();
            })
            .then((data) => {
               
                if (data.valid === false){
                     navigate("/")
                }
                
            })
            .catch((error) => {
            
              console.error("Error making POST request:", error);
            });


        window.scrollTo(0, 0);

        const search = localStorage.getItem('search');
        setImageData([]);
        fetch('https://server.facter.pk/view1', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify({ search, skipCount })


        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setRequestData(data);

                fetch('https://server.facter.pk/viewImage1', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },

                    body: JSON.stringify({ search, skipCount })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {

                        setImageData(data);
                        // setskipCount(skipCount+3); 


                    })
                    .catch(error => {
                        console.error('Error making fetch request:', error);
                    });



            })
            .catch(error => {
                console.error('Error making fetch request:', error);
            });



    }, [localStorage.getItem('search'), skipCount]);


    useEffect(() => {
        setskipCount(0);
        const search = localStorage.getItem('search');
        fetch('https://server.facter.pk/viewCount', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },

                    body: JSON.stringify({ search})
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {

                        setCount(data);
                       


                    })
                    .catch(error => {
                        console.error('Error making fetch request:', error);
                    });




    }, [localStorage.getItem('search')]);






    var currentDate = new Date();
    currentDate = currentDate.toDateString()
    return (

        <Box >

            <Header />

            <Stack direction={'column'} spacing={2} justifyContent={'center'} >


                <Box ml={0} flex={4} p={0} pt={5} sx={{ height: '100vh' }}>


                    <div>
                        {RequestData.slice(0, visiblePosts).map((item, index) => (
                            <Card onClick={() => handleLearnMoreClick(item.id)} sx={{ display: { sm: 'flex', xs: 'block' }, width: { xs: 300, sm: '50%', lg: '70%' }, mt: { xs: 2, sm: 2 }, ml: { xs: 1, sm: 25 }, background: 'White', '&:hover': { cursor: 'pointer' } }}>
                                <CardMedia
                                    sx={{  objectFit:'contain', width:{xs: 300,sm:200}, height: 160, cursor: 'pointer'}}
                                    image={`data:image/png;base64,${Image[index]?.base64Data || ''}`}
                                    title={item.title}
                                />
                                <CardContent>
                                    <Typography sx={{ fontFamily: 'Open Sans, sans-serif', fontSize: { sm: 18, xs: 12 }, fontWeight: 'bold' }} gutterBottom variant="h5" component="div">
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>
                                        {item.added_datetime.split('T')[0]}
                                    </Typography>
                                </CardContent>

                            </Card>
                        ))}
                        <Box display='flex'>

                            <Button onClick={back} Button variant="extended" width={1} sx={{ mb: 1, ml: { xs: 1, sm: 25 }, mt: 5, display: 'block', fontWeight: 'bold', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'black', boxShadow: 'none' } }} >
                                Prev
                            </Button>

                            <Button onClick={loadMorePosts} Button variant="extended" width={1} sx={{ mb: 1, ml: { xs: 1, sm: 2 }, mt: 5, display: 'block', fontWeight: 'bold', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'black', boxShadow: 'none' } }} >
                                Next
                            </Button>

                        </Box>

                    </div>


                </Box>

                <Footer />

            </Stack>

        </Box>


    );

}

export default MainPage;