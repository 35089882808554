
import React, { useState } from 'react';
import {HashRouter as Router, Switch, Route,Routes, Link, BrowserRouter } from 'react-router-dom';
import SignIn from './SignIn'; // Adjust the import path based on your file location
import AdminDashboard from './AdminDashboard';
import Social from './SocialForm';
import Editorial from './EditorialForm';
import Home from './MainPage/mainpage';
import RequestAdd from './Requests/AddRequest'
import RequestShow from './Requests/ShowRequest'
import RequestAdmin from './Requests/adminShowRequest'
import RequestData from './Requests/RequestData'
import RequestAnswer from './Requests/AnswerRequest'
import Addjob from './/JOBS/AddJob'
import NormalSearch from './Search/normalSearch'
import SocialData from './ViewPost/OpenPost'
import GeneReport from './GenerateReport'
import AdvanceSearch from './Search/advanceSearch'
import ArchiveSearch from './Search/archiveSearchFinal'
import View from './viewmore/view2'
import JobShow from './JOBS/ShowJob'
import JobAdmin from './JOBS/adminShowJob'
import JobData from './JOBS/JobData'
import JobAnswer from './JOBS/AnswerJob'
import Latest from './LatestPosts'
import ViewCheck from './viewCheckk'
import ViewCheck2 from './viewCheckk2'
import Update from './updatePost'
import Report from './Headers/dailyReport2'
import Stats from './statsBoard'


function App() {
  return (
  
         
    <Router>

    
          <Routes>
               <Route path="/" element={<SignIn />} />
               <Route path="/admin" element={<AdminDashboard />} />
               <Route path="/home" element={<Home />} />
               <Route path="/social" element={<Social/>} />
               <Route path="/editorial" element={<Editorial/>} />
               
               <Route path="/addrequest" element={<RequestAdd/>} />
               <Route path="/showrequest" element={<RequestShow/>} />
               <Route path="/adminrequest" element={<RequestAdmin/>} />
               <Route path="/datarequest" element={<RequestData/>} />
               <Route path="/answerrequest" element={<RequestAnswer/>} />
               
               <Route path="/addjob" element={<Addjob/>} />
               <Route path="/showjob" element={<JobShow/>} />
               <Route path="/adminjob" element={<JobAdmin/>} />
               <Route path="/datajob" element={<JobData/>} />
               <Route path="/answerjob" element={<JobAnswer/>} />

               <Route path="/search" element={<NormalSearch/>} />
               <Route path="/socialdata" element={<SocialData/>} />
               <Route path="/generatereport" element={<GeneReport/>} />
               <Route path="/advancesearch" element={<AdvanceSearch/>} />
               <Route path="/archivesearch" element={<ArchiveSearch/>} />
               <Route path="/view" element={<View/>} />


               <Route path="/latest" element={<Latest/>} />

               <Route path="/viewCheck" element={<ViewCheck/>} />
               <Route path="/viewCheck2" element={<ViewCheck2/>} />
               <Route path="/update" element={<Update/>} />
               <Route path="/report" element={<Report/>} />

               <Route path="/statsBoard" element={<Stats/>} />
              
          </Routes>
    </Router>

  );
}

export default App;