import Header from "../Headers/Header";
import Side from "../SideBar2";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import {
  useMediaQuery,
  createTheme,
  ThemeProvider,
  Stack,
} from "@mui/material";
import { useNavigate, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardActions,
  Fab,
  CardContent,
  useTheme,
  Divider,
  Button,
  CardMedia,
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Footer from "../footer";

const theme = createTheme();

function MainPage(props) {
  //const isAuthenticated = useAuth();
  const navigate = useNavigate();
  //const storedToken = localStorage.getItem('token');
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);
  const [RequestData, setRequestData] = useState([]);
  const [selectedButton, setSelectedButton] = useState("");

  const handleLearnMoreClick = (url) => {
    //console.log('Clicked "Learn More" for card with ID:', id);
    window.open(url, "_blank");
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleadvance = (event) => {
    const search = localStorage.getItem("search");

    fetch("https://server.facter.pk/archivesearch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ keyword, type }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setRequestData(data);
      })
      .catch((error) => {
        console.error("Error making fetch request:", error);
      });
  };

  const [keyword, setKeyword] = React.useState("");
  const [type, setType] = React.useState("");

  const handleChange1 = (event) => {
    setKeyword(event.target.value);
  };

  const handleChange4 = (event) => {
    setSelectedButton(event.target.value);
    setType(event.target.value);
  };
  const expandChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var currentDate = new Date();
  currentDate = currentDate.toDateString();
  return (
    <Box>
      <Header />

      <Stack direction={"column"} spacing={2} justifyContent={"center"}>
        <Box ml={0} flex={4} p={0} pt={5} sx={{ height: "100vh" }}>
          <Box sx={{ padding: "2%", paddingX: "15%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "2%",
              }}
            >
              <Typography
                fontFamily="Libre Franklin"
                gutterBottom
                color="gray"
                component="div"
                marginRight="2%"
                fontWeight="bold"
                fontSize="10px"
              >
                Filters By Source:
              </Typography>
              <Button
                onClick={handleChange4}
                value="AFP-Pakistan"
                sx={{
                  backgroundColor:
                    selectedButton === "AFP-Pakistan" ? "darkgray" : "black",
                  color: "white",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor:
                      selectedButton === "AFP-Pakistan" ? "gray" : "darkgray",
                  },
                }}
              >
                AFP-Pakistan
              </Button>
              <Button
                onClick={handleChange4}
                value="Soch"
                sx={{
                  backgroundColor:
                    selectedButton === "Soch" ? "darkgray" : "black",
                  color: "white",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor:
                      selectedButton === "Soch" ? "gray" : "darkgray",
                  },
                }}
              >
                SOCH
              </Button>
              <Button
                onClick={handleChange4}
                value="geo"
                sx={{
                  backgroundColor:
                    selectedButton === "geo" ? "gray" : "black",
                  color: "white",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor:
                      selectedButton === "geo" ? "gray" : "darkgray",
                  },
                }}
              >
                GEO
              </Button>

              <Button
                onClick={handleChange4}
                value=""
                sx={{
                  backgroundColor:
                    selectedButton === "" ? "gray" : "black",
                  color: "white",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor:
                      selectedButton === "" ? "gray" : "darkgray",
                  },
                }}
              >
                Remove all filters
              </Button>
              <Box sx={{ flexGrow: 1 }} />{" "}
              {/* This box takes up remaining space */}
              <TextField
                onChange={handleChange1}
                variant="outlined"
                placeholder="Search..."
                size="small"
                sx={{ width: "200px" }} // Adjust width as needed
              />
              <Button
                onClick={handleadvance}
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor: "darkgray",
                  },
                }}
              >
                Search
              </Button>
            </Box>

            <Divider />

            {RequestData.length > 0 && (
              <Box
                bgcolor="black"
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                  direction: "row",
                  padding: "0.8%",
                  marginTop: "3%",
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                  color: "white",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Georgia",
                    marginLeft: "2%",
                  }}
                >
                  Title
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Georgia",
                    marginRight: "3%",
                  }}
                >
                  Source
                </Typography>
              </Box>
            )}

            {RequestData.map((item) => (
              <Card
                onClick={() => handleLearnMoreClick(item.url)}
                sx={{
                  width: { xs: "100%", sm: "100%", lg: "100%" },
                  ml: { xs: 0, sm: 0 },
                  mt: { xs: 0, sm: 0 },
                  background: "White",
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      fontFamily="Georgia"
                      gutterBottom
                      component="div"
                      fontSize="18px"
                    >
                      Description: {item.title}
                    </Typography>

                    <Typography
                      fontFamily="Libre Franklin"
                      gutterBottom
                      color="gray"
                      component="div"
                      marginRight="2%"
                      paddingLeft="3%"
                      fontWeight="bold"
                      fontSize="15px"
                    >
                      {item.category}
                    </Typography>
                  </Box>

                  <Typography
                    mt={1}
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontFamily: "Libre Franklin",
                      maxWidth: "700px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontWeight: "bold",
                    }}
                  >
                    {item.date_time}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>

        <Footer />
      </Stack>
    </Box>
  );
}

export default MainPage;
