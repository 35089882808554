
import Header from '../Headers/Header'
import Side from '../SideBar2'
import Box from '@mui/material/Box';
import React ,{useState} from 'react';
import { useMediaQuery, createTheme, ThemeProvider, Stack ,Typography,Button,TextField,CardContent,Card,Radio,FormControlLabel,selectedOption,RadioGroup,FormControl,Fab} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Footer from '../footer'


  

const theme = createTheme();
function SocialForm(props) {

    
    

   
    const navigate = useNavigate();
    const storedToken = localStorage.getItem('token');
    const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);

   

     //for handling the data insertion

     const [link, setText1] = useState('');
     const [description, setText2] = useState('');
     const [task, setText3] = useState('');
     const [date, setText4] = useState('');
     const [time, setText5] = useState('');
     
     
 
     const handleTextChange1 = (event) => {
      setShouldScrollToTop(false);
         setText1(event.target.value);
       };
 
     const handleTextChange2 = (event) => {
      setShouldScrollToTop(false);
         setText2(event.target.value);
       };
 
       const handleTextChange3 = (event) => {
        setShouldScrollToTop(false);
         setText3(event.target.value);
       };
 
       const handleTextChange4 = (event) => {
        setShouldScrollToTop(false);
         setText4(event.target.value);
       };
 
       const handleTextChange5 = (event) => {
        setShouldScrollToTop(false);
         setText5(event.target.value);
       };
 
 
     

    const [shouldScrollToTop, setShouldScrollToTop] = useState(true);
    React.useEffect(() => {
  
      if (shouldScrollToTop) {
        window.scrollTo(0, 0);
        setShouldScrollToTop(true); // Reset the state after scrolling
      }
    }, [shouldScrollToTop]);
      //if (!storedToken) {
        // No token found, navigate to login
        //navigate('/login');
      //} else {
        //console.log('sdds');
        //const parsedToken =  JSON.parse(storedToken);
        //console.log(parsedToken.expiration,'dd');
        //console.log(Date.now());
        //if (parsedToken.expiration < Date.now()) {
         
          // Token has expired, clear it from local storage and navigate to login
          //localStorage.removeItem('token');
          //navigate('/login');
  
  
        //}
      //}
  

     //for form submission
        const FormSubmit = async (event) => {
        const currentTimestamp = Date.now();
        const timestamp = new Date(currentTimestamp).toLocaleString();
        const userId=localStorage.getItem('savedId');

          
           try {
            const apiUrl = 'https://server.facter.pk/requestsinsert'; // Replace with your actual API endpoint
        
            const response = await fetch(apiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                
              },
              body: JSON.stringify({
                timestamp: timestamp, description:description,task:task,link:link,time:time,date:date,userId:userId
                
            
              }),
            });
        
            if (!response.ok) {
              console.error('Failed to submit data:', response.statusText);
              return;
            }
            console.log('Data submitted successfully');
          } catch (error) {
            console.error('Error:', error.message);
          }
           
         
           alert('request submitted');
           navigate('/home');

       
      };

      
  
  
    
        
    
    
      return isTokenCheckComplete ?(
       
        <Box >

           

            <Header/> 

            <Stack direction={'column'} spacing={2} justifyContent={'center'} >
 
           
                    <Box    ml={0} flex={4} p={2} pt={10} sx={{height: '100vh' ,pl:{xs:'4%' ,sm:'7%',md:'14%',lg:'16%',xl:'24%'} }}>
                        <Typography variant="h6" color="black" fontFamily='Georgia' sx={{ml:{sm:8,sx:7},fontSize:24, fontWeight: 'bold'}}>Request a Fact-Check</Typography>
                            <Box sx={{ display:{xs:'block' , sm:'block' }  , paddingTop:'10px',paddingBottom:'19px' , width:{sm:700,xs:300}, ml:{sm:8,xs:2},mt:2 ,mb:6 }}>

                              

                                    <Card sx={{ml:{sm:0,xs:3} , mt:2,width:{sm:400,xs:250,md:600,lg:750} }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom>
                                            Link
                                            </Typography>
                                            <TextField
                                            label="Your Answer"
                                            multiline
                                            rows={0.5}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            onChange={handleTextChange1}
                                            />
                                           
                                        </CardContent>
                                    </Card>


                                    <Card sx={{ml:{sm:0,xs:3} , mt:1,width:{sm:400,xs:250,md:600,lg:750} , }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom>
                                            Description
                                            </Typography>
                                            <TextField
                                            label="Your Answer"
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            onChange={handleTextChange2}
                                            />
                                           
                                        </CardContent>
                                    </Card>

                                    <Card sx={{ml:{sm:0,xs:3} , mt:1,width:{sm:400,xs:250,md:600,lg:750} }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom>
                                            Task
                                            </Typography>
                                            <TextField
                                            label="Your Answer"
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            onChange={handleTextChange3}
                                            />
                                           
                                        </CardContent>
                                    </Card>

                                    <Card sx={{ml:{sm:0,xs:3} , mt:1,width:{sm:400,xs:250,md:600,lg:750} }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom>
                                            Available Date
                                            </Typography>
                                            <Typography variant="h8" gutterBottom>
                                            e.g jul-24-2024
                                            </Typography>
                                            <TextField
                                            label="Your Answer"
                                            multiline
                                            rows={0.5}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            onChange={handleTextChange4}
                                            />
                                           
                                        </CardContent>
                                    </Card>

                                    <Card sx={{ml:{sm:0,xs:3} , mt:1,width:{sm:400,xs:250,md:600,lg:750}}}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom>
                                            Available Time
                                            </Typography>
                                            <Typography variant="h7" gutterBottom>
                                            e.g 12 am
                                            </Typography>
                                            <TextField
                                            label="Your Answer"
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            onChange={handleTextChange5}
                                            />
                                           
                                        </CardContent>
                                    </Card>





                            </Box>
                            <Button variant="extended" onClick={FormSubmit} sx={{mb:8, ml:8 ,fontWeight: 'bold',backgroundColor: 'black', color: 'white','&:hover': {
                 backgroundColor: 'black'
              } }}>
                                SUBMIT
                            </Button>

                    </Box>

                    <Footer/>
                    
             
              
            </Stack>

        </Box>
          
       
    ):null;

}

export default SocialForm;