import Header from "../Headers/Header";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Imran from "../image/imranKhan.jpeg";
import previous from "../image/previous.png";
import next from "../image/next.png";
import Grid from "@mui/material/Grid";
import ButtonBase from '@mui/material/ButtonBase';

import Side from "../SideBar2";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import {
  useMediaQuery,
  createTheme,
  ThemeProvider,
  Stack,
} from "@mui/material";
import { useNavigate, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardActions,
  Fab,
  CardContent,
  useTheme,
  Divider,
  Button,
  CardMedia,
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Footer from "../footer";
import { setDate } from "date-fns";

const theme = createTheme();

function MainPage(props) {
  const [visiblePosts, setVisiblePosts] = useState(13); // Number of posts initially visible

  const loadMorePosts = () => {
    //setVisiblePosts(prev => prev + 3); // Increase the number of visible posts
    const skip = skipCount + 13;

    if (count[0]["COUNT(*)"] > skip) {
      setskipCount(skip, () => {
        console.log(skipCount);
        const search = localStorage.getItem("search");
        setImageData([]);
        setRequestData([]);

        fetch("https://server.facter.pk/view1", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ search, skipCount }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            setRequestData(data);

            fetch("https://server.facter.pk/viewImage1", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },

              body: JSON.stringify({ search, skipCount }),
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.json();
              })
              .then((data) => {
                // Assuming data is an array of objects with 'filename' and 'base64image' properties
                setImageData(data);
                // Adjust timeZone if needed
              })
              .catch((error) => {
                console.error("Error making fetch request:", error);
              });
          })
          .catch((error) => {
            console.error("Error making fetch request:", error);
          });
      });
    }
  };

  const back = () => {
    //setVisiblePosts(prev => prev + 3);
    const skip = skipCount - 13;

    if (skip >= 0) {
      setskipCount(skip, () => {
        console.log(skipCount);
        console.log(localStorage.getItem("search"));
        const search = localStorage.getItem("search");
        setImageData([]);
        setRequestData([]);

        fetch("https://server.facter.pk/view1", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ search, skipCount }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            setRequestData(data);

            fetch("https://server.facter.pk/viewImage1", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },

              body: JSON.stringify({ search, skipCount }),
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.json();
              })
              .then((data) => {
                // Assuming data is an array of objects with 'filename' and 'base64image' properties
                setImageData(data);
                // Adjust timeZone if needed
              })
              .catch((error) => {
                console.error("Error making fetch request:", error);
              });
          })
          .catch((error) => {
            console.error("Error making fetch request:", error);
          });
      });
    }
  };
  //const isAuthenticated = useAuth();
  const navigate = useNavigate();
  //const storedToken = localStorage.getItem('token');
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);
  const [RequestData, setRequestData] = useState([]);
  const [Image, setImageData] = useState([]);
  const [skipCount, setskipCount] = useState(0);
  const [count, setCount] = useState(0);

  const handleLearnMoreClick = (id) => {
    //console.log('Clicked "Learn More" for card with ID:', id);
    localStorage.setItem("clickedRequestId", id);
    navigate("/socialdata");
  };

  useEffect(() => {
    fetch("https://server.facter.pk/validateToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("TOKEN"),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response.json();
      })
      .then((data) => {
        if (data.valid === false) {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });

    window.scrollTo(0, 0);

    const search = localStorage.getItem("search");
    setImageData([]);
    fetch("https://server.facter.pk/view1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ search, skipCount }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setRequestData(data);

        fetch("https://server.facter.pk/viewImage1", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ search, skipCount }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            setImageData(data);
            // setskipCount(skipCount+3);
          })
          .catch((error) => {
            console.error("Error making fetch request:", error);
          });
      })
      .catch((error) => {
        console.error("Error making fetch request:", error);
      });
  }, [localStorage.getItem("search"), skipCount]);

  useEffect(() => {
    setskipCount(0);
    const search = localStorage.getItem("search");
    fetch("https://server.facter.pk/viewCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ search }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCount(data);
      })
      .catch((error) => {
        console.error("Error making fetch request:", error);
      });
  }, [localStorage.getItem("search")]);

  var currentDate = new Date();
  currentDate = currentDate.toDateString();
  return (
    <Box>
      <Header />

      <Stack direction={"column"} spacing={2} justifyContent={"center"}>
        <Box ml={0} flex={4} p={0} pt={5} sx={{ height: "100vh" }}>
          <Box sx={{ paddingX: "15%" }}>
            <Typography
              sx={{
                marginTop: "5px",
                fontSize: "20px",
                fontWeight: "bold",
                fontFamily: "Gilda Display",
              }}
            >
              {RequestData?.[0]?.type === "Misleading Content/Mal-information"
                ? "MISLEADING CONTENT"
                : RequestData?.[0]?.type ===
                  "Misinformation (Documented & Fact Check by MMfD"
                ? "MISINFORMATION"
                : RequestData?.[0]?.type ===
                  "Verified/Published Fact-Checked Information"
                ? "VERIFIED CONTENT"
                : RequestData?.[0]?.type === "Suspected"
                ? "SUSPECTED CONTENT"
                : RequestData?.[0]?.type === "Hate speech/Incitement"
                ? "HATE SPEECH"
                : RequestData?.[0]?.type}
            </Typography>

            <Divider sx={{ borderWidth: "1.5px" }} />

            <Box sx={{ paddingTop: "2%" }}>
              <Box>
                <Card
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                    },
                  }}
                  onClick={() => handleLearnMoreClick(RequestData[0].id)}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <CardContent>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            fontFamily: "Georgia",
                          }}
                        >
                          {RequestData?.[0]?.title || "Default Title"}
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{
                            fontSize: "10px",
                            fontFamily: "Libre Franklin",
                          }}
                        >
                          <br />
                          <br />
                          <br />
                          {RequestData?.[0]?.added_datetime.split("T")[0] ||
                            "Default date"}
                          <br />
                        </Typography>

                        <Typography
                          color="text.secondary"
                          sx={{
                            fontSize: "10px",
                            fontFamily: "Libre Franklin",
                          }}
                        >
                          <br />
                          <br />
                          <br />
                          {RequestData?.[0]?.type ||
                            "Default date"}
                          <br />
                        </Typography>
                      </CardContent>
                    </Grid>
                    <Grid item xs={4}>
                      <LazyLoadImage
                        src={`data:image/png;base64,${
                          Image[0]?.base64Data || ""
                        }`}
                        alt="Lazy loaded example"
                        effect="blur"
                        placeholderSrc={Imran}
                        style={{
                          marginLeft: "50%",
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  paddingY: "2%",
                  gap: "16px",
                }}
              >
                {RequestData.slice(1).map((item, index) => (
                  <Card
                    key={item.id}
                    onClick={() => handleLearnMoreClick(item.id)}
                    sx={{
                      flexBasis: {
                        xs: "100%",
                        sm: "calc(50% - 16px)",
                        md: "calc(25% - 16px)",
                      },
                      marginBottom: "2%",
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <LazyLoadImage
                      alt="Lazy loaded example"
                      effect="blur"
                      placeholderSrc={Imran}
                      src={`data:image/png;base64,${
                        Image[index + 1]?.base64Data || ""
                      }`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <CardContent>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "Georgia",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        sx={{ fontSize: "10px", fontFamily: "Libre Franklin" }}
                      >
                        <br />
                        {item.added_datetime.split("T")[0]}
                        <br />
                        {item.type === "Misleading Content/Mal-information"
                      ? "Misleading"
                      : item.type === "Misinformation (Documented & Fact Check by MMfD"
                      ? "MisInformation"
                       : item.type === "Verified/Published Fact-Checked Information"
                      ? "Verified"
                       : item.type === "Suspected"
                      ? "Suspected"
                       : item.type === "Hate speech/Incitement"
                      ? "Hate-Speech"
                      : item.type}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>

            <Box display="flex">
            <ButtonBase onClick={back} sx={{ display: "block", mx: "auto", mt: 5 }}>
        <img src={previous} alt="Next" style={{ width: "50px", height: "auto" }} />
      </ButtonBase>

              <ButtonBase onClick={loadMorePosts} sx={{ display: "block", mx: "auto", mt: 5 }}>
        <img src={next} alt="Next" style={{ width: "50px", height: "auto" }} />
      </ButtonBase>
            </Box>
          </Box>
        </Box>

        <Footer />
      </Stack>
    </Box>
  );
}

export default MainPage;
