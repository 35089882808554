import Header from "../Headers/NavBar";
import Header2 from "../Headers/NavBar2";
import Imran from "../image/imranKhan.jpeg"
import Box from "@mui/material/Box";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Footer from "../footer";
import {
  CardMedia,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  Stack,
  Typography,
  Button,
  TextField,
  CardContent,
  Card,
  Radio,
  FormControlLabel,
  selectedOption,
  RadioGroup,
  FormControl,
  Fab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AspectRatio } from "@mui/icons-material";

const theme = createTheme();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function EditorialForm(props) {
  const navigate = useNavigate(); //for navigation
  const storedToken = localStorage.getItem("token"); //store token
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(false);
  const [data, setData] = useState([]);
  const [image, setImage] = useState(null);

  const handleTypographyClick = () => {};

  //token cheking fucntion
  React.useEffect(() => {

    fetch("https://server.facter.pk/validateToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("TOKEN"),
       
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
          
        }
    
        return response.json();
      })
      .then((data) => {
         
          if (data.valid === false){
               navigate("/")
          }
          
      })
      .catch((error) => {
      
        console.error("Error making POST request:", error);
      });
    //if (!storedToken) {
    // No token found, navigate to login
    //navigate('/login');
    //} else {
    //console.log('sdds');
    //const parsedToken =  JSON.parse(storedToken);
    //console.log(parsedToken.expiration,'dd');
    //console.log(Date.now());
    //if (parsedToken.expiration < Date.now()) {

    // Token has expired, clear it from local storage and navigate to login
    //localStorage.removeItem('token');
    //navigate('/login');

    //}
    // }

    setTokenCheckComplete(true);

    const requestId = localStorage.getItem("clickedRequestId");

    fetch("https://server.facter.pk/SocialAnswer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ requestId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);

        const filename = data[0].filename;

        fetch("https://server.facter.pk/downloadImage", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ filename }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.blob();
          })
          .then((imageBlob) => {
            const imageUrl = URL.createObjectURL(imageBlob);
            setImage(imageUrl);
          })
          .catch((error) => {
            console.error("Error fetching image:", error);
          });
      })
      .catch((error) => {
        console.error("Error making fetch request:", error);
      });
  }, []);

  return (
    <Box>
      <Header />
      <Header2 />

      <Stack direction={"column"} spacing={2} justifyContent={"center"}>
        {data.map((item, index) => (
          <Box sx={{ flexDirection: { xs: 'column', sm: 'row' }}}>
            <Card sx={{marginTop:"2.5%"}}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  paddingTop: "30.25%",
                  marginTop: "60px",
                  bgcolor:"#333333"
                }}
              >
                 <img
                  src={image}
                
                  loading="eager"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    cursor: "pointer",
                    
                  }}
                /> 
              </Box>
            </Card>

            <Box
              sx={{
                paddingY: "1%",
                display: "flex-col",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "80%",
                marginBottom: "2%",
                marginLeft: "12%",
                marginTop: "1%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Gilda Display",
                  fontWeight: "medium",
                  fontSize: "30px",
                }}
              >
                {item.title}
              </Typography>

              <Typography
                sx={{
                  fontFamily: "Libre Franklin",
                  fontWeight: "medium",
                  fontSize: "12px",
                }}
              >
                {item.sources === "Other"
                  ? `Posted on ${item.other1}`
                  : `Posted on ${item.sources}`}{" "}
                at {item.timee} on {item.datee}
              </Typography>

              <Typography
                sx={{
                  fontFamily: "Libre Franklin",
                  fontWeight: "medium",
                  fontSize: "12px",
                  
                }}
              >
                <b>Category</b>: {item.type}
              </Typography>
            </Box>

            <Box
              sx={{
                
                paddingX: "2%",
                paddingY: "1%",
                display: "flex",
                justifyContent: "space-between",
                
                height: "100%",
                width: {xs:"90%", sm:"75%"},
                marginBottom: "10%",
                marginLeft: "10%",
              }}
            >
              <Box  sx={{width:{xs:"85%", sm:"68%"}}}>
                <Typography sx={{fontFamily:"Georgia", fontWeight:"bold",fontSize:"20px"}}>What is happening in the post ?</Typography>
                <Typography sx={{fontFamily:"Georgia",fontSize:"18px"}}> <div dangerouslySetInnerHTML={{ __html: item.whats }} /></Typography>
                

                <Typography sx={{fontFamily:"Georgia", fontWeight:"bold",fontSize:"20px"}}>Editorial Notes</Typography>
                <Typography sx={{fontFamily:"Georgia",fontSize:"18px"}}> <div dangerouslySetInnerHTML={{ __html: item.note }} /></Typography>
                
              </Box>

              <Box
             
             sx={{
              width: "30%",
              height: "60%",
              position: "sticky",
              top: 0,
              marginLeft:"2%",
              zIndex: 1000,
              paddingX: "10px",
              paddingY: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adding box shadow
              bgcolor: "white", // Optional: Add a background color if needed
              display: { xs: 'none', sm: 'block' }
            }}
              >
                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia", fontWeight:"bold",fontSize:"17px"}}>Category</Typography>
                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia",fontSize:"15px"}}>   {item.category1 === 'Other' ? `${item.other2}` : `${item.category1}`} </Typography>


                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia", fontWeight:"bold",fontSize:"17px",marginTop:"5px"}}>Tags</Typography>
                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia",fontSize:"15px"}}>    {item.tags} </Typography>

                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia", fontWeight:"bold",fontSize:"17px",marginTop:"5px"}}>Account</Typography>
                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia",fontSize:"15px"}}>    {item.account} </Typography>

                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia", fontWeight:"bold",fontSize:"17px",marginTop:"5px"}}>Other Accounts</Typography>
                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia",fontSize:"15px"}}>    {item.otherAccount} </Typography>

                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia", fontWeight:"bold",fontSize:"17px",marginTop:"5px"}}>Type of deceptive Image/video if any</Typography>
                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia",fontSize:"15px"}}>     {item.category2 === 'Other' ? `${item.other3}` : `${item.category2}`}  </Typography>

                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia", fontWeight:"bold",fontSize:"17px",marginTop:"5px"}}>Link</Typography>
                <Typography sx={{marginLeft:"5px",fontFamily:"Georgia",fontSize:"15px"}}>      <a href={item.link}>Visit Source</a> </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

export default EditorialForm;
