
import Header from '../Headers/Header'
import Side from '../SideBar2'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useMediaQuery, createTheme, ThemeProvider, Stack } from '@mui/material';
import { useNavigate ,useHistory} from 'react-router-dom';
import React, {useEffect, useState}from 'react';
import { Box,Typography,Card,CardActions,Fab,CardContent,useTheme, Divider,Button,CardMedia } from "@mui/material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Footer from '../footer'


const theme = createTheme();



function MainPage(props) {
  //const isAuthenticated = useAuth();
  const navigate = useNavigate();
  //const storedToken = localStorage.getItem('token');
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);
  const [RequestData, setRequestData] = useState([]);



  const handleLearnMoreClick = (id) => {

    //console.log('Clicked "Learn More" for card with ID:', id);
    localStorage.setItem('clickedRequestId', id);
    navigate('/datarequest');
    };

  useEffect(() => {
            window.scrollTo(0, 0);

            const userId= localStorage.getItem('savedId');

            fetch('https://server.facter.pk/requestsuserget', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({userId})

            })
            .then(response => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setRequestData(data);
                
            })
            .catch(error => {
                console.error('Error making fetch request:', error);
            });

          
                
  }, []);


    
    var currentDate = new Date();
    currentDate=currentDate.toDateString()
    return   (
       
        <Box >

            <Header/>    

            <Stack direction={'column'} spacing={2} justifyContent={'center'} >
               <Box  ml={0} flex={4} p={0} pt={5} sx={{height: '100vh'  }}>

                     
                          <Box sx={{ display:{xs:'block' , sm:'flex' } }}>

                                  <Typography variant="h6" color="#434343"  fontFamily={ '"Helvetica Neue"'} sx={{paddingLeft:{xs:'5px',sm:'5px'}, borderLeft: '4px solid red',ml:{sm:15,xs:2}, fontWeight: 'bold' }}>YOUR REQUEST</Typography>
                                  
                          </Box>

                          <Box sx={{ ml:15,mt:3, display:{xs:'block' , sm:'block'  }, gap: 2, pb:20 }}>
                                {RequestData.map(item => (

                                      <Card sx={{ width:{xs:300,sm:700} , mt:{xs:2,sm:2} , background:'White'}}>
                                         
                                          <CardContent>
                                              <Typography fontFamily={ '"Helvetica Neue"'} gutterBottom variant="h5" component="div">
                                              Description: {item.description}
                                              </Typography>
                                              <Typography variant="body2" color="text.secondary"  sx={{ maxWidth: '300px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                                              link: {item.link}
                                              </Typography>
                                              <Typography variant="body2" color="text.secondary"  sx={{ maxWidth: '300px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                                              Posted at: {item.timestamp}
                                              </Typography>

                                              <Typography variant="body2" color="text.secondary"  sx={{ maxWidth: '300px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                                              Given time: {item.time}  , {item.date}
                                              </Typography>

                                              <Typography variant="body2" color="text.secondary"  sx={{ maxWidth: '300px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                                              status: {item.status}
                                              </Typography>

                                              
                                          </CardContent>
                                          <CardActions>
                                          
                                              <Button size="small"  onClick={() => handleLearnMoreClick(item.id)}  disabled={item.status === 'pending'} sx={{ color:'white', background:' #434340' ,'&:hover': {
                                                  background:'black',borderColor: 'black' ,borderStyle: 'solid' , borderWidth:0,
                                              }}}>
                                                  Learn More
                                                  <InsertLinkIcon/>
                                              </Button>
                                          </CardActions>
                                      </Card>

                                  

                                  ))

                                  
                                  
                                  
                                  
                                  
                                  
                                  }

                                  

                          </Box>

                        

                          </Box>


           
             
              <Footer/>
              
            </Stack>

        </Box>
          
       
    );

}

export default MainPage;