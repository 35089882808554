import {Paper, Box , Typography ,Chip,Divider,Card,CardContent,Button,CardActions,Fab,List,ListItemAvatar,ListItemText,Avatar,ListItem} from "@mui/material";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TagIcon from '@mui/icons-material/Tag';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import React from "react";


const archives = [
    { title: '#imranKhan', url: 'https://example.com/archive1' },
    { title: '#Pti', url: 'https://example.com/archive2' },
    { title: '#CYBERCRIME', url: 'https://example.com/archive2' },
    { title: '#PMLN', url: 'https://example.com/archive2' },
    // Add more archive objects as needed
  ];

  const Topics = [
    { title: 'Election', url: 'https://example.com/archive1' },
    { title: 'Voting', url: 'https://example.com/archive2' },
    { title: 'Social', url: 'https://example.com/archive2' },
    { title: 'False Information', url: 'https://example.com/archive2' },
    // Add more archive objects as needed
  ];



 function RightBar () {

    return(


      <Typography>sdsd</Typography>

  
    );
}
export default RightBar;