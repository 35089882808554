
import Header from '../Headers/NavBar'
import Header2 from '../Headers/NavBar2'
import Side from '../SideBar2'
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import testImage from '../image/imranKhan.jpeg'
import Footer from '../footer'

import { CardMedia, useMediaQuery, createTheme, ThemeProvider, Stack, Typography, Button, TextField, CardContent, Card, Radio, FormControlLabel, selectedOption, RadioGroup, FormControl, Fab } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const theme = createTheme();

function EditorialForm(props) {


  const navigate = useNavigate();//for navigation
  const storedToken = localStorage.getItem('token');//store token
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(false);
  const [data, setData] = useState([]);
  const [image, setImage] = useState(null);


  //to handle the add request 
  const addRequest = () => {
    navigate('/addrequest')
  };

  //to show the  request 
  const showRequest = () => {
    navigate('/showrequest')
  };


  //to show the  request 
  const addJob = () => {
    navigate('/addjob')
  };


  //token cheking fucntion
  React.useEffect(() => {


    //if (!storedToken) {
    // No token found, navigate to login
    //navigate('/login');
    //} else {
    //console.log('sdds');
    //const parsedToken =  JSON.parse(storedToken);
    //console.log(parsedToken.expiration,'dd');
    //console.log(Date.now());
    //if (parsedToken.expiration < Date.now()) {

    // Token has expired, clear it from local storage and navigate to login
    //localStorage.removeItem('token');
    //navigate('/login');


    //}
    // }

    setTokenCheckComplete(true);

    const jobId = localStorage.getItem('clickedRequestId');

    fetch('https://server.facter.pk/JobAnswer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ jobId })

    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setData(data);


        const filename = data[0].filename;

        fetch('https://server.facter.pk/downloadImage', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ filename })
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then(imageBlob => {
            const imageUrl = URL.createObjectURL(imageBlob);
            setImage(imageUrl);
          })
          .catch(error => {
            console.error('Error fetching image:', error);
          });


      })
      .catch(error => {
        console.error('Error making fetch request:', error);
      });



  }, []);





  return (

    <Box >

      <Header />
      <Header2 />

      <Stack direction={'column'} spacing={2} justifyContent={'center'} >


        <Box ml={0} flex={4} p={2} pt={10} sx={{ height: '100vh' }} >


          <Box sx={{ display: { xs: 'block', sm: 'block' }, paddingTop: '10px', paddingBottom: '19px', width: { sm: 700, xs: 300 }, ml: { sm: '14%', xs: '3%', lg: '20%' }, mt: 2, mb: 6 }}>

            {data.map((item, index) => (


              <Card sx={{ ml: { sm: 13, xs: 3 }, mt: 2, width: { sm: 800, xs: 250 }, boxShadow: 'none' }}>
                <CardMedia
                  sx={{ width: '100%', height: 800, cursor: 'pointer', objectFit: 'contain' }}
                  image={image}
                  title={item.title}

                />
                <CardContent>

                  <Typography color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: { sm: 30, xs: 28 }, fontWeight: 'bold' }}>
                    {item.title}
                  </Typography>

                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 15 }}>

                  </Typography>
                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 15 }}>
                    {item.sources === 'Other' ? `Posted on ${item.other1}` : `Posted on ${item.sources}`} at  {item.timee} on {item.datee}
                  </Typography>


                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 15 }}>

                  </Typography>
                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 15 }}>
                    {item.type}
                  </Typography>


                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 15 }}>

                  </Typography>
                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 15 }}>
                    {item.tags}
                  </Typography>

                  <Box pt={2}></Box>
                  <Typography variant="h6" fontWeight='bold' color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 24 }}>
                    What is happening in the post?
                  </Typography>
                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 20 }}>
                    {item.whats}
                  </Typography>




                  <Box pt={2}></Box>
                  <Typography variant="h6" fontWeight='bold' color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 24 }}>
                    Account
                  </Typography>
                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 20 }}>
                    {item.account}
                  </Typography>


                  <Box pt={2}></Box>
                  <Typography variant="h6" fontWeight='bold' color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 24 }}>
                    Other Accounts
                  </Typography>
                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 20 }}>
                    {item.otherAccount}
                  </Typography>


                  <Box pt={2}></Box>
                  <Typography variant="h6" fontWeight='bold' color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 24 }}>
                    Category
                  </Typography>
                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 20 }}>
                    {item.category1 === 'Other' ? `${item.other2}` : `${item.category1}`}
                  </Typography>


                  <Box pt={2}></Box>
                  <Typography variant="h6" fontWeight='bold' color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 24 }}>
                    Editorial Notes
                  </Typography>
                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 20 }}>
                    {item.note}
                  </Typography>





                  <Box pt={2}></Box>
                  <Typography variant="h6" fontWeight='bold' color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 24 }}>
                    Type of deceptive Image/video if any
                  </Typography>
                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 20 }}>
                    {item.category2 === 'Other' ? `${item.other3}` : `${item.category2}`}
                  </Typography>


                  <Box pt={2}></Box>
                  <Typography variant="h6" fontWeight='bold' color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 24 }}>
                    Link
                  </Typography>
                  <Typography variant="h6" color="black" fontFamily='Open Sans, sans-serif' sx={{ ml: { sm: 0, sx: 0 }, fontSize: 20 }}>

                    Visit <a href={item.link}>Visit Source</a>
                  </Typography>



                </CardContent>
              </Card>

            ))}


          </Box>


        </Box>

        <Footer />
      </Stack>

    </Box>


  );

}




export default EditorialForm;