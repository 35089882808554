
import Header from '../Headers/Header'
import Side from '../SideBar2'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useMediaQuery, createTheme, ThemeProvider, Stack } from '@mui/material';
import { useNavigate ,useHistory} from 'react-router-dom';
import React, {useEffect, useState}from 'react';
import { Box,Typography,Card,CardActions,Fab,CardContent,useTheme, Divider,Button,CardMedia } from "@mui/material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Footer from '../footer';


const theme = createTheme();

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));



function MainPage(props) {
  //const isAuthenticated = useAuth();
  const navigate = useNavigate();
  //const storedToken = localStorage.getItem('token');
  const [isTokenCheckComplete, setTokenCheckComplete] = useState(true);
  const [RequestData, setRequestData] = useState([]);



  const handleLearnMoreClick = (id) => {

    //console.log('Clicked "Learn More" for card with ID:', id);
    localStorage.setItem('clickedRequestId', id);
    navigate('/socialdata');
    };


    const handleadvance = (event) => {
              if (!date) {
                setDate('2024-01-01');
            }
            if (!date2) {
              const currentDate = new Date();
              const year = currentDate.getFullYear();
              let month = currentDate.getMonth() + 1;
              month = month < 10 ? '0' + month : month; // Add leading zero if needed
              let day = currentDate.getDate();
              day = day < 10 ? '0' + day : day; // Add leading zero if needed
              var datef = `${year}-${month}-${day}`;
              setDate2(datef);
              
            }


            console.log(date2);
            fetch('https://server.facter.pk/advancesearch', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({keyword,type,tag,date,date2})

            })
            .then(response => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setRequestData(data);
                console.log(data);
            })
            .catch(error => {
                console.error('Error making fetch request:', error);
            });

          


    };

    const [expanded, setExpanded] = React.useState('panel1');
    const [keyword, setKeyword] = React.useState('');
    const [date, setDate] = React.useState('');
    const [tag, setTag] = React.useState('');
    const [type, setType] = React.useState('');
    const [date2, setDate2] = React.useState('');

    const handleChange1 = (event) => {
          setKeyword(event.target.value);
    };
    const handleChange2 = (event) => {
        setDate(event.target.value);
    };
    const handleChange3 = (event) => {
        setTag(event.target.value);
    };    
    const handleChange4 = (event) => {
        setType(event.target.value);
    };

    const handleChange5 = (event) => {
      setDate2(event.target.value);
  };
    const expandChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


  useEffect(() => {
            window.scrollTo(0, 0);  
  }, []);


    
    var currentDate = new Date();
    currentDate=currentDate.toDateString()
    return   (
       
        <Box >

            <Header/>    

            <Stack direction={'column'} spacing={2} justifyContent={'center'} >
               <Box   ml={0} flex={4} p={0} pt={5} sx={{height: '100vh'  }}>
                  <Accordion marginLeft={20} expanded={expanded === 'panel1'} onChange={expandChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Advance filter list</Typography>
                            </AccordionSummary>
                                <AccordionDetails>

                                    <Box sx={{display:'flex' }} ml={2}>
                                    <Typography  mt={2} sx={{mr:{sm:5,xs:2}, fontWeight:'bold'}}>Keyword  </Typography> 
                                    <TextField onChange={handleChange1} id="filled-basic" label="keyword" variant="filled" />

                                    <Typography mt={2}  sx={{fontWeight:'bold',mr:{sm:5,xs:2},ml:{sm:20,xs:5} }}>Date From </Typography> 
                                    <TextField onChange={handleChange2} id="filled-basic" label="yy-mm-dd" variant="filled" />
                                    </Box>

                                    <Box sx={{display:'flex'}} ml={2} mt={3}>
                                    <Typography  mt={2} mr={9} sx={{fontWeight:'bold'}}>Tag  </Typography> 
                                    <TextField onChange={handleChange3} id="filled-basic" label="tag" variant="filled" />

                                    <Typography mt={2}  sx={{fontWeight:'bold',mr:{sm:5,xs:2},ml:{sm:24,xs:5} }}>Date To </Typography> 
                                    <TextField onChange={handleChange5} id="filled-basic" label="yy-mm-dd" variant="filled" />


                                    </Box>

                                    <Box sx={{display:'block'}} ml={2} mt={3}>
                                    <Typography  mt={6} mr={9} sx={{fontSize:13,color:'grey', fontFamily:'Open Sans, sans-serif'}}>Please do not select any category if you want to search all the posts.  </Typography> 
                                        <FormControl component="fieldset">
                                                    <RadioGroup onChange={handleChange4}>
                                                        <FormControlLabel value="Suspected" control={<Radio />} label="Suspected Infomation (unverified content)" />
                                                        <FormControlLabel value="Misinformation (Documented & Fact Check by MMfD" control={<Radio />} label="Misinformation (Documented & Fact Check by MMfD" />
                                                        <FormControlLabel value="Verified/Published Fact-Checked Information" control={<Radio />} label="Verified/Published Fact-Checked Information" />
                                                        <FormControlLabel value="Misleading Content/Mal-information" control={<Radio />} label="Misleading Content/Mal-information" />
                                                        <FormControlLabel value="Hate speech/Incitement" control={<Radio />} label="Hate speech/Incitement" />
                                                        
                                                    </RadioGroup>
                                        </FormControl>
                                     </Box>


                                     <Box   sx={{ml:{xs:30,sm:50}}} mt={3}>
                                        <Button variant="extended" onClick={handleadvance} sx={{ fontWeight: 'bold',backgroundColor: 'black', color: 'white' ,'&:hover': {backgroundColor: 'black', boxShadow: 'none', boxShadow: 'none' } }}>
                                            SEARCH
                                        </Button>
                                     </Box>


                                    
                                </AccordionDetails>
                    </Accordion>



                    

                          <Box sx={{ display:{xs:'block' , sm:'flex' } }}>

                                  <Typography variant="h6" color="#434343"  fontFamily={ '"Helvetica Neue"'} sx={{paddingLeft:{xs:'5px',sm:'5px'}, borderLeft: '4px solid red',ml:{sm:15,xs:2}, fontWeight: 'bold' ,mt:10 }}>SEARCH RESULTS</Typography>
                                  
                          </Box>

                          <Box sx={{ ml:{sm:'6%',xs:'3%',md:25},mt:3, display:{xs:'block' , sm:'block'  }, gap: 2 ,mt:10, }}>
                                {RequestData.map(item => (

                                      <Card onClick={() => handleLearnMoreClick(item.id)} sx={{ width:{xs:300,sm:'50%',lg:'70%'},ml:{xs:1, sm:1} , mt:{xs:2,sm:2} , background:'White' ,'&:hover':{cursor: 'pointer'} }}>
                                         
                                          <CardContent>
                                              <Typography fontFamily={ 'Open Sans, sans-serif'} gutterBottom variant="h5" component="div">
                                               {item.title}
                                              </Typography>
                                              <Typography variant="body2" color="text.secondary"  sx={{ maxWidth: '300px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                                              {item.added_datetime.split('T')[0]}
                                              </Typography>

                                              <Typography variant="body2" color='#64a9dc' fontWeight='bold' mt={2} pl={0} pr={1} sx={{ maxWidth: '700px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }}>
                                                  {item.type}
                                               </Typography>
                                              

                                              
                                          </CardContent>
                                          
                                      </Card>

                                  

                                  ))

                                  
                                  
                                  
                                  
                                  
                                  
                                  }

                                  

                          </Box>

                        

                          </Box>


           
             
              <Footer/>
              
            </Stack>

        </Box>
          
       
    );

}

export default MainPage;